import { mapDirectusCTAs } from '../general';

export const mapDirectusCtaTilesBlock = block => {
  return {
    heading: block.heading,
    body: block.body,

    sections: block.tile_sections
      .sort((a, b) => (a.sort >= b.sort ? 1 : -1))
      .map(({ tile_section_id: item }) => ({
        subheading: item.subheading,
        tiles: mapDirectusCTAs(item.tiles, 0, true, 'cta_items_id'),
      })),
    buttons: mapDirectusCTAs(block.buttons, 0, true, 'cta_items_id').sort((a, b) => (a.sort >= b.sort ? 1 : -1)),
  };
};
