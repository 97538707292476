import { useState, useEffect } from 'react';
const TypographyTester = () => {
  const styles = [
    'type-title',
    'type-heading',
    'type-heading-alt',
    'type-subheading',
    'type-subheading-small',
    'type-body',
    'type-body-small',
    'type-body-base',
  ];

  const [selectedStyles, setSelectedStyles] = useState([]);

  const handleStyleChange = event => {
    const value = event.target.value;
    setSelectedStyles(prev => {
      if (prev.includes(value)) {
        return prev.filter(style => style !== value);
      }

      return [...prev, value];
    });
  };

  return (
    <div className="my-16">
      <h1 className="type-heading mb-4">Type Pairing Tester</h1>
      <p>Select styles you want to pair. Text is editable.</p>
      <div className="mb-4">
        {styles.map(style => (
          <label key={style} className="mx-2 inline-block">
            <input type="checkbox" value={style} onChange={handleStyleChange} checked={selectedStyles.includes(style)} className="mr-2" />
            {style}
          </label>
        ))}
      </div>
      {selectedStyles.length > 0 && (
        <div className="mt-16">
          {selectedStyles.map((style, index) => (
            <div key={index} className={`${style} my-4`} contentEditable>
              The quick brown fox jumps over the lazy dog.
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const getCurrentBreakpoint = width => {
  if (width >= 1536) return '2xl';
  if (width >= 1280) return 'xl';
  if (width >= 1024) return 'lg';
  if (width >= 768) return 'md';
  if (width >= 640) return 'sm';
  return 'default';
};

export const TypographyStyleGuide = () => {
  const [activeBreakpoint, setActiveBreakpoint] = useState(false);

  // Effect to handle window resizing
  useEffect(() => {
    const handleResize = () => {
      setActiveBreakpoint(getCurrentBreakpoint(window.innerWidth));
    };

    window.addEventListener('resize', handleResize);

    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const styles = [
    {
      className: 'type-title',
      description: 'Title text',
      fontSize: {
        default: '45', // text-[45]
        sm: '70', // sm:text-[70]
        md: '85', // md:text-[85]
        lg: '110', // lg:text-[110]
        xl: '140', // xl:text-[140]
        '2xl': '170', // 2xl:text-[170]
      },
      fontWeight: '300',
      lineHeight: '100%', // leading-none
      letterSpacing: '-2.5%', // tracking-tight
    },
    {
      className: 'type-heading',
      description: 'Heading text',
      fontSize: {
        default: '35', // text-[35]
        sm: '35', // sm:undefined, use default
        md: '45', // md:text-[45]
        lg: '50', // lg:text-[50]
        xl: '60', // xl:text-[60]
        '2xl': '80', // 2xl:text-[80]
      },
      fontWeight: '300',
      lineHeight: '100%', // leading-none
      letterSpacing: '-2.5%', // tracking-tight
    },
    {
      className: 'type-heading-alt',
      description: 'Alternate heading text',
      fontSize: {
        default: '35', // text-[35]
        sm: '35', // sm:undefined, use default
        md: '35', // md:text-[35]
        lg: '40', // lg:text-[40]
        xl: '50', // xl:text-[50]
        '2xl': '60', // 2xl:text-[60]
      },
      fontWeight: '300',
      lineHeight: '100%', // leading-none
      letterSpacing: '-2.5%', // tracking-tight
    },
    {
      className: 'type-subheading',
      description: 'Subheading text',
      fontSize: {
        default: '25', // text-[25]
        sm: '25', // sm:undefined, use default
        md: '30', // md:text-[30]
        lg: '35', // lg:text-[35]
        xl: '40', // xl:text-[40]
        '2xl': '50', // 2xl:text-[50]
      },
      fontWeight: '400',
      lineHeight: '100%', // leading-none
      letterSpacing: '-2.5%', // tracking-tight
    },
    {
      className: 'type-subheading-small',
      description: 'Small subheading text',
      fontSize: {
        default: '30', // text-[30]
        sm: '30',
        md: '30',
        lg: '35', // lg:text-[35]
        xl: '40', // xl:text-[40]
        '2xl': '45', // 2xl:text-[45]
      },
      fontWeight: '400',
      lineHeight: '100%', // leading-none
      letterSpacing: '-2.5%', // tracking-tight
    },
    {
      className: 'type-body',
      description: 'Body text',
      fontSize: {
        default: '14', // text-sm
        sm: '14',
        md: '16', // md:text-base
        lg: '24', // lg:text-[24]
        xl: '28', // xl:text-[28]
        '2xl': '28',
      },
      fontWeight: '400',
      lineHeight: '125%', // leading-[1.05]
      letterSpacing: '0%', // tracking-normal
    },
    {
      className: 'type-body-small',
      description: 'Body small text',
      fontSize: {
        default: '14', // text-sm
        sm: '14',
        md: '16', // md:text-[16]
        lg: '18', // lg:text-[18]
        xl: '24', // xl:text-[24]
        '2xl': '24',
      },
      fontWeight: '400',
      lineHeight: '125%', // leading-[1.05]
      letterSpacing: '0%', // tracking-normal
    },
    {
      className: 'type-body-base',
      description: 'Body small base',
      fontSize: {
        default: '14', // text-sm
        sm: '14',
        md: '14', // md:text-[16]
        lg: '16', // lg:text-[18]
        xl: '16', // xl:text-[24]
        '2xl': '16',
      },
      fontWeight: '400',
      lineHeight: '150%', // leading-[1.05]
      letterSpacing: '0%', // tracking-normal
    },
  ];

  const breakpoints = {
    '2xl': 'greater than 1536px',
    xl: 'greater than 1280px',
    lg: 'greater than 1024px',
    md: 'greater than 768px',
    sm: 'greater than 640px',
    default: 'greater than 0px',
  };

  return (
    <div className="wrapper py-16">
      <h1 className="type-heading mb-8">Typography Style Guide</h1>
      <p>
        The current breakpoint will be in <span className="weight-600 text-xumoBerry">highlighted</span> under font sizes as you resize the window.
      </p>
      {styles.map((style, index) => (
        <div key={index} className="my-16">
          <div className={style.className}>{style.description}. Lorem ipsum et dolar</div>
          <ul className="mt-4">
            <li>
              <strong>Font Weight:</strong> {style.fontWeight}
            </li>
            <li>
              <strong>Line Height:</strong> {style.lineHeight}{' '}
              <i>({(Number(style.lineHeight.replace('%', '')) / 100) * parseInt(style.fontSize[activeBreakpoint])}px at this breakpoint)</i>
            </li>
            <li>
              <strong>Letter Spacing:</strong> {style.letterSpacing}{' '}
              <i>({(Number(style.letterSpacing.replace('%', '')) / 100) * parseInt(style.fontSize[activeBreakpoint])}px at this breakpoint)</i>
            </li>
            <li>
              <strong>Font Sizes:</strong>
              <ul>
                {Object.entries(style.fontSize).map(([breakpoint, size], i) => (
                  <li className={`ml-4 ${activeBreakpoint === breakpoint ? 'weight-600 text-xumoBerry' : ''}`} key={i}>
                    <strong className="inline-block w-64">
                      {breakpoint} ({breakpoints[breakpoint]}):
                    </strong>{' '}
                    {size}px
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </div>
      ))}
      <TypographyTester />
    </div>
  );
};
