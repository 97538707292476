import React from 'react';
import { Highlighted } from '.';
import { IImage } from '../types';
import { chunkIntoN } from '../utils/chunkIntoN';
import stripOuterHtmlTags from '../utils/stripHtmlTags';
import { DropDownBlock } from './DropDownBlock';
import { useRouter } from 'next/router';
import Link from 'next/link';
import ReactModal from 'react-modal';
import { useSanitizedTranslation } from '../hooks';

export type ChannelSearchBlockProps = {
  anchor?: string;
  heading?: string;
  genres: { heading: string; channels: string[] }[];
  searchPlaceholder?: string;
  backgroundColor: string;
  backgroundImage?: IImage;
};

export const ChannelSearchBlock: React.FC<ChannelSearchBlockProps> = ({ anchor, heading, genres, searchPlaceholder, backgroundColor, backgroundImage }) => {
  const { t } = useSanitizedTranslation();
  const router = useRouter();
  const isTv = router.asPath.indexOf('/products/xumo-tv') === 0;

  const getDataAttributes = (obj: Record<string, any>): Record<string, any> => {
    return Object.entries(obj).reduce((attrs: Record<string, any>, [key, value]) => {
      if (value !== null && typeof value !== 'boolean') {
        const modifiedKey = key.replace(/_/g, '-');
        attrs[`data-${modifiedKey}`] = value;
      }
      return attrs;
    }, {});
  };

  const ChannelList = ({ channel = [], app = [], searchValue }) => {
    const chunkedChannels = chunkIntoN(
      channel
        .filter(item => (isTv ? item.xumo_tv : item.xumo_stream_box))
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(val => val),
      3,
    );

    const chunkedApps = chunkIntoN(
      app
        .filter(item => (isTv ? item.xumo_tv : item.xumo_stream_box))
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(val => val),
      3,
    );
    return (
      <div>
        {app && app.length > 0 && (
          <>
            <p className="weight-500 my-2 font-WorkSans text-md tracking-tight">{isTv ? 'Apps' : 'Apps'}</p>
            <ul className="grid w-full grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-3">
              {chunkedApps.map((chunk, index) => (
                <li key={index}>
                  <ul className="text-left">
                    {chunk.map(channel => {
                      return (
                        <li data-i18n={channel.name} {...getDataAttributes(channel)}>
                          <Highlighted text={channel.name} highlight={searchValue} />
                        </li>
                      );
                    })}
                  </ul>
                </li>
              ))}
            </ul>
          </>
        )}
        {channel && channel.length > 0 && (
          <>
            <p className="weight-500 my-2 font-WorkSans text-md tracking-tight">{isTv ? 'Channels' : 'Xumo Premium Channels'}</p>
            <ul className="grid w-full grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-3">
              {chunkedChannels.map((chunk, index) => (
                <li key={index}>
                  <ul className="text-left">
                    {chunk.map(channel => {
                      return (
                        <li data-i18n={channel.name} {...getDataAttributes(channel)}>
                          <Highlighted text={channel.name} highlight={searchValue} />
                        </li>
                      );
                    })}
                  </ul>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    );
  };

  const anchorIsActive = anchor ? new URL('https://a.a' + router.asPath).hash === '#' + anchor : false;

  return (
    <ReactModal isOpen={anchorIsActive} style={{ overlay: { zIndex: 1000, background: 'rgba(0,0,0,.75)' }, content: { borderRadius: '1rem', border: 'none' } }}>
      <div className={'relative flex w-full flex-col'} data-test-id="channel-search-block">
        <Link
          aria-label="Close"
          href="#"
          scroll={false}
          className="weight-100 absolute right-0 z-50 rounded-md p-2 outline outline-2 outline-offset-2 outline-transparent transition-colors hover:outline-xumoTeal"
        >
          <img src="/static/icons/ic_close_fill.svg" width={32} height={32} alt="" />
        </Link>
        <DropDownBlock modal items={genres} ItemComponent={ChannelList} heading={heading ?? ''} searchPlaceholder={searchPlaceholder ?? 'search'} />
      </div>
    </ReactModal>
  );
};
