import React from 'react';
import { motion } from 'framer-motion';
import { IImage } from '../types';
import Head from 'next/head';
import { MotionImage, useSanitizedTranslation } from 'ui';

export type NewsStoryProps = {
  title: string;
  description: string;
  thumbnail: IImage;
  link: string;
  index: number;
  ogImage: { url: string }[];
  slug: string;
};

const imageVariants = {
  initial: {
    scale: 1,
    transition: { type: 'spring', damping: 65, stiffness: 1000, mass: 1, restDelta: 0.000001 },
  },
  hover: {
    scale: 1.04,
    transition: { type: 'spring', damping: 65, stiffness: 1000, mass: 1, restDelta: 0.000001 },
  },
};

const cardVariants = {
  initial: {
    y: 0,
    transition: { type: 'spring', damping: 65, stiffness: 1000, mass: 1 },
  },
  hover: {
    y: -5,
    transition: { type: 'spring', damping: 65, stiffness: 1000, mass: 1 },
  },
};

const OgImage = React.memo(({ url }) => (
  <motion.div data-og className={'relative flex h-[200px] min-h-[120px] w-full items-center justify-center overflow-hidden object-cover'}>
    <motion.img data-src={url} src={url} alt={''} key={url} className="h-full w-full object-cover" variants={imageVariants} unoptimized />
  </motion.div>
));

export const NewsStory = ({ title, description, thumbnail, ogImage, link, slug, index }: NewsStoryProps) => {
  const { t } = useSanitizedTranslation();

  const getNewsJsonLd = () => {
    const obj = {
      '@context': 'https://schema.org',
      '@type': 'NewsArticle',
      mainEntityOfPage: {
        '@type': 'WebPage',
        id: index,
      },
      headline: title,
      image: thumbnail,
      description: description,
    };

    return {
      __html: JSON.stringify(obj),
    };
  };

  const NewsImage = () => (
    <motion.div
      data-cms
      className={'relative flex h-[200px] min-h-[120px] w-full items-center justify-center overflow-hidden bg-xumoBerry object-cover xl:h-[250px]'}
    >
      <MotionImage
        src={thumbnail.src}
        alt={thumbnail.alt ?? ''}
        fill
        objectFit="cover"
        variants={imageVariants}
        sizes={'(max-width: 768px) 100vw, (max-width: 1024px) 50vw, 33vw'}
        priority={index < 3 ? true : false}
      />
    </motion.div>
  );

  return (
    <>
      <Head>
        <script type="application/ld+json" dangerouslySetInnerHTML={getNewsJsonLd()} />
      </Head>
      <motion.a
        initial="initial"
        animate="initial"
        whileHover="hover"
        href={link || slug}
        className="flex min-h-[365px] w-full cursor-pointer flex-col items-start overflow-hidden rounded-md bg-xumoWhite tracking-tight shadow-none outline outline-2 outline-offset-2 outline-transparent hover:shadow-lg hover:outline-xumoTeal"
        style={{ transition: 'box-shadow 0.2s, outline-color 0.2s' }}
      >
        <>
          <NewsImage />
          <h3 className={`type-subheading-xs mx-6 my-6 line-clamp-3 text-left text-black`} data-i18n={title} dangerouslySetInnerHTML={{ __html: title }} />
          <div
            className={`text-body-base w-full overflow-hidden px-6 pb-6 text-left text-xumoBlack`}
            data-i18n={description}
            dangerouslySetInnerHTML={{ __html: t(description) }}
          />
        </>
      </motion.a>
    </>
  );
};
