import { motion } from 'framer-motion';
import React from 'react';
import { useSanitizedTranslation, useWindowSize } from '../hooks';
import { CTA, IImage } from '../types';
import { Bubble, Button, SplitH1, SplitH2 } from './';
import { Position } from './Bubble';
import { useScrolledTheme, useInViewScroll } from 'ui';
import { useRouter } from 'next/router';

export type BubbleBlockProps = {
  bubbles: { image: IImage; positionConfig: { mobile?: Position; tablet?: Position; desktop: Position }; scale: number }[];
  header: string;
  cta: CTA[];
  backgroundColor: string;
  accent: string;
  body: string;
  headingOne?: boolean;
};

const variants = {
  initial: {
    opacity: 0,
    y: 40,
    scale: 0.9,
  },
  animate: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      type: 'spring',
      staggerChildren: 0.03,
      delayChildren: 0.3,
    },
  },
};

export const BubbleBlock: React.FC<BubbleBlockProps> = ({ bubbles, header, body, cta, backgroundColor, headingOne, accent }) => {
  const { t } = useSanitizedTranslation();
  const { width } = useWindowSize();

  const Heading = headingOne ? SplitH1 : SplitH2;

  const { ref, progress: amt } = useInViewScroll([0, 1], ['start 100vh', 'end 0vh']);
  const { componentInView } = useScrolledTheme({
    ref,
    background: backgroundColor,
    accent,
    text: '#000000',
  });

  const noBubble = false;
  const router = useRouter();
  const isTv = router.asPath.indexOf('/products/xumo-tv') === 0;

  return (
    <motion.section variants={variants} initial="initial" animate="animate" ref={ref} className="relative z-10 w-full flex-col py-16 md:text-left">
      <div className="container relative mx-auto flex flex-col items-center px-8 lg:text-center">
        <Heading variants={variants} content={header} className={`${headingOne ? 'type-title' : 'type-heading'} z-10 flex flex-col`} />
        {body && <motion.div variants={variants} className="type-body py-4 lg:w-1/2" data-i18n dangerouslySetInnerHTML={{ __html: t(body) }} />}
        {isTv && (
          <motion.div className="mt-8 grid grid-cols-1 gap-8 max-sm:w-full md:grid-cols-3">
            <motion.div className="flex flex-col items-center justify-center rounded-3xl bg-white p-8 px-16 text-center shadow-lg max-md:w-full">
              <span className="type-heading text-xumoBerry">250+</span>
              <span className="type-body">
                top apps <br />
                pre-loaded
              </span>
            </motion.div>
            <motion.div className="flex flex-col items-center justify-center rounded-3xl bg-white p-8 px-16 text-center shadow-lg max-md:w-full">
              <span className="type-heading text-xumoBerry">350+</span>
              <span className="type-body">
                free channels <br />
                on Xumo Play
              </span>
            </motion.div>
            <motion.div className="flex flex-col items-center justify-center rounded-3xl bg-white p-8 px-16 text-center shadow-lg max-md:w-full">
              <span className="type-heading text-xumoBerry">15k+</span>
              <span className="type-body">
                free titles <br />
                on Xumo Play
              </span>
            </motion.div>
          </motion.div>
        )}
        <motion.div className="relative w-full 2xl:w-4/5">
          <motion.div
            key="bubble-block-anim"
            className="align-center flex flex-row flex-wrap justify-center py-8 text-center"
            variants={variants}
            initial="initial"
            whileInView="animate"
          >
            {bubbles?.map((bubble, index) => (
              <Bubble noBubble={noBubble} index={index} key={'bubble-' + index} image={bubble} variants={variants} />
            ))}
          </motion.div>
        </motion.div>
        {cta?.length > 0 &&
          cta.map(cta => (
            <div key={cta.label} className="flex w-full justify-center">
              <Button
                href={cta.href}
                label={cta.label}
                styleOverrides={{
                  css: { backgroundColor: '#BB2E76' },
                  tailwind: '',
                }}
                buttonType={'link-primary'}
              />
            </div>
          ))}
      </div>
    </motion.section>
  );
};
