import React, { useRef, useState, useEffect } from 'react';
import { AnimatePresence, motion, useSpring, useTransform, useInView, useMotionValue, animate } from 'framer-motion';
import { useInViewScroll, useSanitizedTranslation, MotionImage } from 'ui';

const squiggleVariant = {
  hidden: {
    opacity: 0,
    transition: {
      when: 'afterChildren',
      delayChildren: 0,
      staggerChildren: 0.025,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.055,
      type: 'spring',
      stiffness: 90,
      damping: 23,
      restDelta: 0.001,
    },
  },
};

const Counter: React.FC<{ toValue: number; description: string }> = ({ toValue, description }) => {
  const initialValue = toValue * (0.8 + Math.random() * 0.15);
  const animatedNumber = useMotionValue(toValue); //, { transition: { duration: 2 } }
  const [number, setNumber] = useState(toValue);
  const [shown, setShown] = useState(false);

  const { t } = useSanitizedTranslation();

  const animateTimer = useRef<any>();
  const ref = useRef<any>();
  const inView = useInView(ref, { margin: '0px 0px 0px 0px' });

  useEffect(() => {
    animatedNumber.onChange(number => setNumber(Math.round(number)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inView && !shown) {
      animatedNumber.set(initialValue);
      animateTimer.current = animate(animatedNumber, toValue, {
        ease: 'circOut',
        duration: 2.5 + Math.random() * 0.5,
      });
      setShown(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <motion.div ref={ref} className="flex flex-col px-16 py-8">
      <span className="type-title">{number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}+</span>
      <span data-i18n className="type-body">
        {t(description)}
      </span>
    </motion.div>
  );
};

export type StatsBlockProps = {
  rowStats?: { number: string | number; description: string }[];
  animatedStats?: { number: number; description: string }[];
};

const variants = {
  initial: {
    opacity: 0,
    y: 40,
    scale: 0.9,
  },
  animate: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      type: 'spring',
      staggerChildren: 0.03,
    },
  },
};

export const StatsBlock: React.FC<StatsBlockProps> = ({
  rowStats = [
    {
      number: '18+',
      description: 'distribution<br/>endpoints',
    },
    { number: '850+', description: 'global channels<br/>powered by Xumo' },
    { number: '50+', description: 'DSP/SSP<br/>partnerships' },
  ],
  animatedStats = [
    {
      number: 1000000000,
      description: 'annual hours streamed',
    },
    {
      number: 10000000000,
      description: 'annual ad impressions generated',
    },
  ],
}) => {
  const { t } = useSanitizedTranslation();
  const { ref, progress } = useInViewScroll([0, 1], ['start 100vh', 'end 50vh']);
  const movement = useSpring(progress, { stiffness: 400, damping: 90 });
  const y = useTransform(progress, [0, 1], ['0', '-15vh']);
  const opacity = useTransform(progress, [0, 0.4, 0.6, 1], [0.0, 0.25, 0.25, 0.0]);

  const sectionRef = useRef();
  const componentInView = useInView(ref, { margin: '-50% 0% -25% 0%' });

  return (
    <>
      <motion.section className="relative z-10 text-center" ref={ref}>
        <motion.div
          initial="initial"
          whileInView="animate"
          viewport={{ once: true }}
          variants={variants}
          className="mt-8 grid grid-cols-1 gap-4 lg:grid-cols-5"
        >
          {[...rowStats].map((s, index, arr) => {
            return (
              <motion.div variants={variants} className={'flex flex-col rounded-xl border-black bg-[#f5f7f8] p-8 text-center'}>
                <span className="type-subheading mb-1">{s.number}</span>
                <span className="type-body-base" data-i18n={s.description} dangerouslySetInnerHTML={{ __html: t(s.description) }} />
              </motion.div>
            );
          })}
        </motion.div>
      </motion.section>
    </>
  );
};
