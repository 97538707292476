import { createContext, useContext, useEffect, useState } from 'react';
import { Website } from '../types';
import { useMeasure } from '@uidotdev/usehooks';

// Function to generate buckets based on Adobe session ID
const generateBuckets = (bucketSizes: number[]) => {
  const sessionId = getAdobeSessionId();
  if (!sessionId) return bucketSizes.map(() => 0); // Return zeros if no session ID found

  const hash = enhancedHash(sessionId);
  return bucketSizes.map((size, i) => (Math.abs(hash + i * 31) % size));
};

// Enhanced hash function with more entropy and salting to account for the lack of variance within adobe session IDs
const enhancedHash = (str: string) => {
  const salt = 'ABTestSalt2024'; // Static salt for added entropy
  const mixedStr = str + salt;

  let hash = 0;

  // Incorporate characters from start, middle, and end
  for (let i = 0; i < mixedStr.length; i += Math.floor(mixedStr.length / 4) || 1) {
    hash ^= (mixedStr.charCodeAt(i) << (i % 16)); // Bit-shifting with XOR for variation
  }

  // Add influence from the sum of all ASCII values
  const asciiSum = mixedStr.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
  hash = (hash + asciiSum) >>> 0; // Ensure unsigned integer

  return hash;
};

// Function to retrieve the Adobe session ID from cookies
const getAdobeSessionId = () => {
  const cookies = document.cookie.split(';');
  for (const cookie of cookies) {
    const [name, value] = cookie.split('=').map((part) => part.trim());
    if (name.startsWith('kndctr_') && name.endsWith('_AdobeOrg_identity')) {
      return decodeURIComponent(value);
    }
  }
  return null;
};

// Define the shape of the context type
export type PageContextType = {
  pageCtx: {
    host: Website.Enterprise | Website.Corporate | Website.Shopper | '';
    isCorporate: boolean;
  };
  setPageCtx: (ctx: {
    host: Website.Enterprise | Website.Corporate | Website.Shopper | '';
    isCorporate: boolean;
  }) => void;
  setTheme: (theme: any) => void;
  theme: any;
  headerHeight: number;
  buckets: number[];
};

export const PageContext = createContext<PageContextType>({
  pageCtx: { host: '', isCorporate: false },
  setPageCtx: () => {},
  theme: {},
  setTheme: () => {},
  headerHeight: 0,
  buckets: [],
});

export const PageProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [pageCtx, setPageCtx] = useState<PageContextType['pageCtx']>({
    host: Website.Shopper,
    isCorporate: false,
  });
  const [theme, setTheme] = useState<any>({});
  const [buckets, setBuckets] = useState<number[]>([]);

  const [headerRef, { height: headerHeight }] = useMeasure();

  useEffect(() => {
    const bucketSizes = [2]; //right now this is set up for a single test with 2 buckets. logic supports additional tests if needed
    setBuckets(generateBuckets(bucketSizes));
  }, []);

  const [ref, { height }] = useMeasure();

  return (
    <PageContext.Provider
      value={{
        pageCtx,
        setPageCtx,
        theme,
        setTheme,
        headerRef,
        headerHeight,
        buckets,
        pageHeight: height
      }}
    >
      <div ref={ref} className="w-full">
        {children}
      </div>
    </PageContext.Provider>
  );
};

// Custom hook to access A/B buckets from the context
export const useAbBuckets = () => {
  const context = useContext(PageContext);
  if (!context) {
    throw new Error('useAbBuckets must be used within a PageProvider');
  }
  return context.buckets;
};
