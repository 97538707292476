'use client';

/* eslint-disable max-len */ import React, { useRef, useState, useCallback, useEffect } from 'react';
import { CTA, IImage } from '../types/common';
import { useRouter } from 'next/router';
import { AnimatePresence, motion, useSpring, useTransform } from 'framer-motion';
import {
  MotionImage,
  CTAList,
  Bullet,
  Button,
  IBullet,
  SplitH1,
  SplitH2,
  SplitH3,
  useInViewScroll,
  useWindowSize,
  useScrolledTheme,
  useSanitizedTranslation,
} from 'ui';
import { makeVariants } from '../utils/makeVariants';
import Image from 'next/image';

export interface CurvedShowcaseBlockProps {}

const squiggleVariant = {
  hidden: {
    opacity: 0,
    transition: {
      when: 'afterChildren',
      delayChildren: 0,
      staggerChildren: 0.025,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.055,
      type: 'spring',
      stiffness: 90,
      damping: 23,
      restDelta: 0.001,
    },
  },
};
1;

const images = [
  {
    src: '/static/images/panorama-contents-1.png',
    alt: '',
    caption:
      'Search across hundreds of apps at once to find favorites by title, actor or genre — and use your voice remote to find your next watch even faster.',
  },
  {
    src: '/static/images/panorama-contents-2.png',
    alt: '',
    caption: 'Curate a personal watchlist of movies and shows from hundreds of different apps all in one place with My List. ',
  },
  {
    src: '/static/images/panorama-contents-3.png',
    alt: '',
    caption:
      'Instantly watch live TV, discover new and popular entertainment or easily access recently watched shows and movies directly from the home screen. ',
  },
];

export const CurvedShowcaseBlock: React.FC<CurvedShowcaseBlockProps> = ({}) => {
  const [[id, direction, random], setPage] = useState([0, 0, Math.random()]);

  const { t } = useSanitizedTranslation();

  // TODO: cms

  const backgroundColor = 'white';
  const primary = 'black';
  const heading = 'Stream easy';
  const body =
    'Xumo TV brings together 250+ apps in a simple intuitive smart TV experience. Search across all apps at once, curate a watchlist with My List and easily discover your next entertainment.';

  const Heading = SplitH2;

  const { ref, progress } = useInViewScroll([0, 1], ['start 100vh', 'end 0vh']);
  const movement = useSpring(progress, { stiffness: 400, damping: 90 });
  const y = useTransform(progress, [0, 1], [-70, 40]);
  const remoteY = useTransform(progress, [0, 1], [-60, 40]);
  const opacity = useTransform(progress, [0, 0.4, 0.6, 1], [0.05, 1, 1, 0.05]);

  const router = useRouter();

  const variants = makeVariants.slideIn();

  const { width } = useWindowSize();
  const isMobile = width <= 719;

  const sectionRef = useRef();
  const { componentInView, colors } = useScrolledTheme({
    ref,
    background: '#E0E7ED',
    accent: '#000000',
    text: '#000000',
  });

  const setSlide = useCallback(
    nid => {
      const newDirection = nid > id ? 1 : -1;

      const newId = (nid + images.length) % images.length;
      setPage([newId, newDirection, Math.random()]);
    },
    [id],
  );

  return (
    <>
      <motion.section ref={ref} className="w-full overflow-hidden sm:pb-0 sm:pt-0" data-testid={'swirl-section'}>
        <motion.div
          initial="initial"
          whileInView="animate"
          viewport={{ once: true, margin: '-50% 0% -50% 0%' }}
          variants={variants}
          style={{ color: colors.text }}
          className={`wrapper relative mx-auto flex min-h-[400px] w-full flex-col items-center justify-center pt-16 lg:pt-16`}
        >
          <Heading variants={variants} content={heading} className={`type-heading z-10 flex flex-col text-inherit transition-colors`} />
          {body && (
            <motion.div
              variants={variants}
              className="type-body py-4 text-inherit transition-colors lg:w-1/2"
              data-i18n
              dangerouslySetInnerHTML={{ __html: t(body) }}
            />
          )}
          <div className="mt-2 w-full text-inherit">
            <button
              style={{
                color: id === 0 ? '#BB2E76' : 'inherit',
                borderColor: id === 0 ? '#BB2E76' : 'transparent',
              }}
              className="type-body-bold border-0 border-b-2 bg-transparent px-4 pb-2 leading-tight transition-colors max-md:text-sm md:mx-8"
              onClick={() => setSlide(0)}
            >
              Search
              <br />
              simplified
            </button>

            <button
              style={{
                color: id === 1 ? '#BB2E76' : 'inherit',
                borderColor: id === 1 ? '#BB2E76' : 'transparent',
              }}
              className="type-body-bold border-0 border-b-2 bg-transparent px-4 pb-2 leading-tight transition-colors max-md:text-sm md:mx-8"
              onClick={() => setSlide(1)}
            >
              Personalized
              <br />
              watchlist
            </button>
            <button
              style={{
                color: id === 2 ? '#BB2E76' : 'inherit',
                borderColor: id === 2 ? '#BB2E76' : 'transparent',
              }}
              className="type-body-bold border-0 border-b-2 bg-transparent px-4 pb-2 leading-tight transition-colors max-md:text-sm md:mx-8"
              onClick={() => setSlide(2)}
            >
              Effortless
              <br />
              discovery
            </button>
          </div>
        </motion.div>
        <div className="flex w-full flex-col items-center justify-center pb-16 lg:pb-32">
          <div className="relative flex h-[275px] w-full justify-center md:h-[375px] lg:h-[450px]">
            <motion.div style={{ y }} className="absolute h-full w-full">
              <Image alt="" src="/static/images/panoramic-frame.svg" fill className="h-full w-full object-fill" />
            </motion.div>
            <div className="wrapper absolute -bottom-1 mx-auto">
              <div className="relative z-10 mx-auto w-[325px] md:w-[450px] lg:w-[600px]" style={{ aspectRatio: 1.56875 }}>
                <Image
                  src="/static/images/tv-panorama.png"
                  fill
                  objectFit="fill"
                  alt=""
                  style={{ filter: 'brightness(0.6)' }}
                  className="h-full w-full object-fill"
                />
                {/* preload workaround */}
                {images.map(img => (
                  <div className="inset-unset fixed -left-[100%] h-full w-full object-fill opacity-0">
                    <p>{images[id].caption}</p>
                    <Image src={images[id].src} alt={images[id].alt} fill priority sizes="100vw" />
                  </div>
                ))}

                <div
                  style={{
                    transform: 'perspective(500px) rotateX(1deg)',
                    outline: '1px solid transparent',
                    position: 'absolute',
                    top: '0.5%',
                    left: '0.9%',
                    right: '1%',
                    bottom: '13%',
                    zIndex: 10,
                    background: 'black',
                    overflow: 'hidden',
                    willChange: 'transform',
                    backfaceVisibility: 'hidden',
                  }}
                >
                  <AnimatePresence custom={direction}>
                    <motion.div
                      style={{ transform: 'scale(2)', zoom: 0.5 }}
                      className="absolute h-full w-full"
                      key={images[id].src + random}
                      custom={direction}
                      initial={direction => ({
                        opacity: 0,
                        x: 5 * direction + '%',
                      })}
                      animate={{
                        opacity: 1,
                        x: '0%',
                      }}
                      exit={direction => ({
                        opacity: 1,
                        x: '0%',
                        transition: { duration: 0.5 },
                      })}
                    >
                      <Image
                        src={images[id].src}
                        alt={images[id].alt}
                        fill
                        objectFit="fill"
                        sizes="100vw"
                        className="inset-unset absolute h-full w-full object-fill"
                      />
                    </motion.div>
                  </AnimatePresence>
                </div>

                <div
                  style={{
                    position: 'absolute',
                    bottom: '-10%',
                    right: '-12%',
                    zIndex: 11,
                    width: '20%',
                    aspectRatio: 0.71,
                  }}
                >
                  <MotionImage style={{ y: remoteY }} src="/static/images/re-panorama.png" fill objectFit="contain" className="h-full w-full object-contain" />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-16 flex w-full justify-center">
            <p className="type-body mx-2 w-full md:w-1/2 xl:w-1/3">{images[id].caption}</p>
          </div>
          <div className="mt-6 flex w-full justify-center">
            <Button
              href={'#shop'}
              label={'Learn more'}
              styleOverrides={{
                css: { backgroundColor: '#BB2E76' },
                tailwind: '',
              }}
              buttonType={'link-primary'}
            />
          </div>
        </div>
      </motion.section>
    </>
  );
};
