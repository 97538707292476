/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-len */
import { motion, useSpring, useTransform, useInView, AnimatePresence } from 'framer-motion';
import { useContext, useRef, useEffect, useState, useCallback } from 'react';
import { Button, CTA, CTAList, IImage, PageContext, useInViewScroll, useSanitizedTranslation } from 'ui';
import { SplitH1, SplitH2, SplitH3 } from './';
import { MotionImage } from './AnimatedImage';
import { useRouter } from 'next/router';

const variants = {
  hidden: {
    opacity: 0,
    y: 30,
    transition: {
      when: 'afterChildren',
      delayChildren: 0,
      staggerChildren: 0.025,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      staggerChildren: 0.05,
      type: 'spring',
      stiffness: 90,
      damping: 23,
    },
  },
};

const channelLogos = [
  [
    '/bubbles/channel-nbc-news-now.jpg',
    '/bubbles/channelLogo_americasTestKitchen.png',
    '/bubbles/channel-dateline.jpg',
    '/bubbles/channel-divorceCourt.jpg',
    '/bubbles/channel-lol.webp',
  ],
  [
    '/bubbles/channel-cine.webp',
    '/bubbles/channel-dateline.jpg',
    '/bubbles/channel-golfpass.webp',
    '/bubbles/channelLogo_million.png',
    '/bubbles/channel-nbc-news-now.jpg',
  ],
  [
    '/bubbles/channelLogo_bobthebuilder.png',
    '/bubbles/channel-Hallmark-Movies.jpg',
    '/bubbles/channel-heartland.webp',
    '/bubbles/channel-unsolved-mysteries.webp',
    '/bubbles/channelLogo_evil_kllers.png',
  ],
  [
    '/bubbles/channel-cornhole.webp',
    '/bubbles/channelLogo_AXS.png',
    '/bubbles/channel-cowboy.webp',
    '/bubbles/channel-pattrn.webp',
    '/bubbles/channel-stingray.webp',
  ],
  [
    '/bubbles/channel-cbc.webp',
    '/bubbles/channel-failarmy.webp',
    '/bubbles/channel-ff.webp',
    '/bubbles/channelLogo_FilmRiseFreeMoviesus.png',
    '/bubbles/channelLogo_pga.png',
  ],
];

const distLogos = [
  '/static/images/dist-logos/google.png',
  '/static/images/dist-logos/samsung.png',
  '/static/images/dist-logos/vizio.png',
  '/static/images/dist-logos/redbox.png',
  '/static/images/dist-logos/roku.png',
];

const amounts = ['+72', '+42', '+71', '+83', '+88'];

const sections = [
  {
    heading: 'Our CMS is built for FAST',
    body: 'After ingesting and storing videos and metadata, we leverage our intuitive programming and optimization to provide data insights and drive monetization.',
    cta: {
      label: 'Become a partner',
      href: '/enterprise/contact',
      backgroundColor: '#155772',
      textColor: '#ffffff',
    },
  },
  {
    heading: 'We make<br/>monetization simple',
    body: 'Our SSAI stack has 50+ DSP and SSP partners and 18+ custom global distribution endpoints, so monetization and management are easy, effective, and customizable for your needs. Advanced ads management solutions generate industry-leading fill rates.',
    cta: {
      label: 'Become a partner',
      href: '/enterprise/contact',
      backgroundColor: '#155772',
      textColor: '#ffffff',
    },
  },
  {
    heading: 'Leveraging data to<br/> drive decisions',
    body: 'We provide custom and actionable playout insights provided through dedicated dashboards.',
    cta: {
      label: 'Become a partner',
      href: '/enterprise/contact',
      backgroundColor: '#155772',
      textColor: '#ffffff',
    },
  },
  {
    heading: 'REPORT: The State of<br/> FAST 2023',
    body: `This in-depth look at today's audience and advertiser trends will arm buyers with the best understanding of why and how to incorporate FAST into their media strategies to reach consumers in the converging premium video landscape.`,
    cta: {
      label: 'Download now',
      href: 'https://xumo-marketing.directus.app/assets/e2754548-53d6-4a8d-8a74-bd61d0975973.pdf',
      target: '_blank',
      backgroundColor: '#155772',
      textColor: '#ffffff',
    },
  },
];

export const RotatingScreensBlock: React.FC<{
  headingOne?: boolean;
  heading: string;
  body: string;
  subheading?: string;
  copyBlocks?: any;
  backgroundColor?: string;
  accent?: string;
  ctas: CTA[];
  fineprint?: string;
  images: IImage[];
}> = ({ headingOne, heading, subheading, copyBlocks, body, backgroundColor, accent, ctas, fineprint, images }) => {
  const { setTheme } = useContext(PageContext);
  const { ref, progress } = useInViewScroll([0, 1], ['start 100vh', 'end 0vh']);
  const amt = useSpring(progress, { stiffness: 400, damping: 90 });
  const { t } = useSanitizedTranslation();

  const [[id, direction, random], setPage] = useState([0, 0, Math.random()]);
  const setSlide = useCallback(
    nid => {
      const newDirection = nid > id ? 1 : -1;

      const newId = (nid + sections.length) % sections.length;
      setPage([newId, newDirection, Math.random()]);
    },
    [id],
  );

  const containerRotate = useTransform(amt, [0, 1], ['0deg', '4deg']);
  const containerY = useTransform(amt, [0, 1], ['0vh', '-15vh']);

  const playSection = useRef(null);
  const isInView = useInView(ref, { margin: '-50% 0px -50% 0px' });

  const Heading = headingOne ? SplitH1 : SplitH2;
  const SubHeading = headingOne ? SplitH2 : SplitH3;

  useEffect(() => {
    if (isInView) {
      setTheme({
        background: backgroundColor,
        accent,
        text: '#000000',
      });
    }
  }, [accent, backgroundColor, isInView, setTheme]);

  const [current, setCurrent] = useState(0);

  const timerRef = useRef<any>(0);

  const toggleSlide = () => {
    timerRef.current = setTimeout(() => {
      setCurrent(current => {
        return (current + 1) % images.length;
      });
      toggleSlide();
    }, 3500);
  };

  useEffect(() => {
    toggleSlide();
    return () => clearTimeout(timerRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logoVariants = {
    hidden: {
      y: 10,
      opacity: 0,
    },
    show: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.3,
        delayChildren: 0.2,
        staggerChildren: 0.05,
      },
    },
  };

  const router = useRouter();
  const currentHash = router.asPath.split('#')[1];

  useEffect(() => {
    if (currentHash === 'advertisers') {
      setSlide(3);
    }
  }, [currentHash]);

  const rightBlock = (
    <div className="relative z-20 flex w-full min-w-full flex-col items-start text-left">
      <SplitH3 content={sections[id].heading} variants={variants} className={`type-subheading w-full`} />
      <motion.div
        variants={variants}
        className="type-body-small mb-5 w-full pr-8 pt-5"
        dangerouslySetInnerHTML={{ __html: t(sections[id].body) }}
        data-i18n={sections[id].body}
      />

      {/* TODO: Replace with CtaList */}
      {!!sections[id].cta && (
        <>
          <motion.div className="flex space-x-5 py-5" variants={variants}>
            <CTAList ctas={[sections[id].cta]} />
          </motion.div>
        </>
      )}
      {copyBlocks?.map((block: any, id: number) => (
        <div key={'rotating' + id} className="mt-6 border-t-[1px] border-solid border-black pt-5">
          {block.heading && (
            <SubHeading content={block.heading} className={`weight-700 type-subheading mb-6 text-3xl leading-tight max-md:mt-3 md:max-w-[65%]`} />
          )}
          {block.body && (
            <motion.div
              variants={variants}
              className={`weight-300 type-body mt-5 text-lg md:max-w-full`}
              dangerouslySetInnerHTML={{ __html: t(block.body as string) }}
              data-i18n={block.body}
            />
          )}
          {!!block.ctas.length && (
            <div className="flex ">
              <CTAList ctas={block.ctas} />
            </div>
          )}
        </div>
      ))}
    </div>
  );

  return (
    <>
      <motion.section ref={ref} className="wrapper w-full py-8 lg:py-16" data-testid={'rotating-screens-section'}>
        <Heading content={heading} variants={variants} className={`${headingOne ? 'type-title' : 'type-heading'} text-center`} />
        <div className="type-body-base flex w-full flex-wrap items-center justify-center py-16 text-center tracking-tight max-lg:text-[18px] lg:space-x-2">
          <div
            onClick={() => setSlide(0)}
            data-i18n="Content Management System"
            className={
              (id === 0 ? 'weight-600 text-black outline-xumoTeal' : 'cursor-pointer text-xumoCharcoal outline-transparent hover:text-xumoBlack') +
              ' no-shift rounded-full px-4 py-1 outline outline-2 max-lg:my-2'
            }
          >
            Content Management System
          </div>
          <div
            onClick={() => setSlide(1)}
            data-i18n="Server-side Ad Insertion Stack"
            className={
              (id === 1 ? 'weight-600 text-black outline-xumoTeal' : 'cursor-pointer text-xumoCharcoal outline-transparent hover:text-xumoBlack') +
              ' no-shift rounded-full px-4 py-1 outline outline-2 max-lg:my-2'
            }
          >
            Server-side Ad Insertion Stack
          </div>
          <div
            onClick={() => setSlide(2)}
            data-i18n="Actionable Insights"
            className={
              (id === 2 ? 'weight-600 text-black outline-xumoTeal' : 'cursor-pointer text-xumoCharcoal outline-transparent hover:text-xumoBlack') +
              ' no-shift rounded-full px-4 py-1 outline outline-2 max-lg:my-2'
            }
          >
            Actionable Insights
          </div>
          <div
            onClick={() => setSlide(3)}
            data-i18n="FAST Resources"
            className={
              (id === 3 ? 'weight-600 text-black outline-xumoTeal' : 'cursor-pointer text-xumoCharcoal outline-transparent hover:text-xumoBlack') +
              ' no-shift rounded-full px-4 py-1 outline outline-2 max-lg:my-2'
            }
          >
            FAST Resources
          </div>
        </div>

        {/* <div className="text-inherit w-full mt-8 mb-4 flex flex-col max-md:items-center md:justify-center max-md:space-y-4 md:flex-row md:space-x-4 lg:space-x-8">
          
            <button 
              style={{
                color: id === 0 ? '#155772' : 'inherit',
                borderColor: id === 0 ? '#155772' : 'transparent',
              }}
              className="hide-breaks type-body-bold border-0 border-b-2 px-4 pb-2 max-md:text-sm bg-transparent leading-tight transition-colors" 
              onClick={() => setSlide(0)}>
              Content <br/>Management
              System
            </button>

            <button 
              style={{
                color: id === 1 ? '#155772' : 'inherit',
                borderColor: id === 1 ? '#155772' : 'transparent',
              }}
              className="hide-breaks type-body-bold border-0 border-b-2 px-4 pb-2 max-md:text-sm bg-transparent leading-tight transition-colors"  
              onClick={() => setSlide(1)}>
              
            </button>

            <button 
              style={{
                color: id === 2 ? '#155772' : 'inherit',
                borderColor: id === 2 ? '#155772' : 'transparent',
              }}
              className="hide-breaks type-body-bold border-0 border-b-2 px-4 pb-2 max-md:text-sm bg-transparent leading-tight transition-colors"  
              onClick={() => setSlide(2)}>
              Actionable <br/>Insights
            </button>

            <button 
              style={{
                color: id === 3 ? '#155772' : 'inherit',
                borderColor: id === 3 ? '#155772' : 'transparent',
              }}
              className="hide-breaks type-body-bold border-0 border-b-2 px-4 pb-2 max-md:text-sm bg-transparent leading-tight transition-colors"  
              onClick={() => setSlide(3)}>
              FAST <br/>Resources
            </button>
          </div> */}
        <div className="relative mx-auto flex min-h-[500px] w-full flex-col-reverse justify-center text-left text-black md:px-10 lg:flex-row">
          <div className="mb-4 flex w-full items-center justify-center lg:w-1/2">
            <AnimatePresence>
              <motion.div
                animate={{ opacity: isInView ? 1 : 0.2, x: isInView ? -10 : 0 }}
                style={{ perspective: '800px' }}
                className="relative flex h-[475px] w-full items-start justify-center max-lg:my-5  md:h-[500px]"
              >
                <AnimatePresence>
                  {images[current].src && (
                    <motion.div
                      key={images[current].src}
                      initial={{ opacity: 0, x: -20, y: 10, rotateY: '-10deg' }}
                      animate={{ opacity: 1, x: 0, y: 0, rotateY: '0deg' }}
                      exit={{ opacity: 0, x: 20, y: -10, rotateY: '2deg' }}
                      transition={{ type: 'spring' }}
                      style={{ transformOrigin: 'left' }}
                      className="absolute left-2 aspect-video w-full rounded-lg object-left md:left-8 lg:left-0 lg:w-[110%]"
                    >
                      <motion.div className="relative aspect-video w-full rounded-lg object-left">
                        <MotionImage
                          fill={true}
                          alt={images[current].alt ?? ''}
                          src={images[current].src}
                          sizes={`(max-width: 768px) 100vw, (max-width: 1600px) 75vw, 50vw`}
                          className="absolute left-0 top-0 aspect-video h-full w-full object-contain object-left"
                        />
                      </motion.div>

                      <motion.div variants={logoVariants} initial="hidden" animate="show" exit="hidden" className="flex w-full max-xl:flex-col">
                        <motion.div variants={logoVariants} className="relative mr-16 h-[80px] w-[160px]">
                          <MotionImage
                            fill
                            className="h-full w-full object-contain object-left"
                            sizes={`(max-width: 768px) 50vw, (max-width: 1600px) 20vw, 15vw`}
                            src={distLogos[current]}
                            alt=""
                          />
                        </motion.div>
                        <div className="flex items-center xl:justify-center">
                          {channelLogos[current].map(img => (
                            <motion.div
                              key={img + ' ' + current}
                              variants={logoVariants}
                              className="relative mr-2 h-[40px] max-h-[40px] w-[40px] max-w-[40px] overflow-hidden rounded-full bg-xumoOnyx lg:h-[50px] lg:max-h-[50px] lg:w-[50px] lg:max-w-[50px]"
                            >
                              <MotionImage
                                fill
                                className="h-full w-full object-cover"
                                sizes={`(max-width: 768px) 25vw, (max-width: 1600px) 20vw, 10vw`}
                                src={img}
                                alt=""
                              />
                            </motion.div>
                          ))}
                          <motion.div
                            variants={logoVariants}
                            className="type-body flex h-[50px] max-h-[50px] w-[50px] max-w-[50px] items-center justify-center rounded-full bg-white lg:h-[50px] lg:max-h-[50px] lg:w-[50px] lg:max-w-[70px]"
                          >
                            <span>{amounts[current]}</span>
                          </motion.div>
                        </div>
                      </motion.div>
                      {fineprint && current === 0 && (
                        <div className="type-body mt-2 text-[12px]" data-i18n={fineprint} dangerouslySetInnerHTML={{ __html: fineprint }} />
                      )}
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            </AnimatePresence>
          </div>
          <motion.div ref={playSection} className="relative flex w-full flex-col lg:w-1/2  lg:pl-16" viewport={{ once: true, margin: '-30% 0% -30% 0%' }}>
            {rightBlock}
          </motion.div>
        </div>
      </motion.section>
    </>
  );
};
