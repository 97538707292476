/* eslint-disable react/no-unescaped-entities */
import React, { useMemo, useState, useRef, useEffect, memo } from 'react';
import { AnimatePresence, motion, useSpring, useTransform } from 'framer-motion';
import { CTA, IImage } from '../types';
import { MotionImage } from './AnimatedImage';
import { Button } from '.';
import { makeVariants } from '../utils/makeVariants';
import { useScrolledTheme, useInViewScroll, SplitH2, useSanitizedTranslation } from 'ui';
import Head from 'next/head';
import { BazaarVoiceBlock } from './BazaarVoiceBlock';
import Image from 'next/image';
import Script from 'next/script';
import Link from 'next/link';
import DevicesJSON from 'ui/devices.json';

import { useMeasure, useWindowSize } from '@uidotdev/usehooks';

const devicesJSON = DevicesJSON.reduce((acc, item) => {
  const items = item.sizes.map(val => ({
    ...item,
    size: val,
    heading: item.heading.replace('$size', val + '"'),
    slug:
      '/products/xumo-tv/' +
      item.heading
        .replace('$size', val)
        .split(' ')
        .map(word => word.toLowerCase())
        .join('-'),
  }));

  return [...acc, ...items];
}, []);

export type ProductListingBlockProps = {
  deviceId: string;
};

const DeviceItem: React.FC<{
  strap: string;
  heading: string;
  body: string;
  sizes: any;
  features: any;
  images: IImage[];
  buttons: any[];
  productId: string;
}> = ({ slug, strap, buttons, heading, body, sizes, features, images, logo, padLogo, moreInfo, flex, comingSoon, productId, redirectUrl }) => {
  const [showInfo, setShowInfo] = useState(false);

  const getGalleryJsonLd = () => {
    const obj = {
      '@context': 'https://schema.org',
      '@type': 'Product',
      '@id': 'https://www.xumo.com/products/xumo-tv',
      brand: 'Xumo',
      name: heading,
      description: body,
      image: images.src,
    };

    return {
      __html: JSON.stringify(obj),
    };
  };

  const variants = makeVariants.slideIn();

  return (
    <>
      <Head>
        <script type="application/ld+json" dangerouslySetInnerHTML={getGalleryJsonLd()} />
      </Head>
      <Link
        href={slug}
        className={`flex w-full flex-col justify-between rounded-xl bg-white p-4 text-black outline outline-2 outline-offset-2 outline-transparent transition-all hover:outline-xumoTeal`}
      >
        <div className="flex flex-col gap-4">
          <div className="relative mb-6 flex">
            <div className="relative aspect-video w-full">
              <MotionImage {...images} sizes="(max-width: 768px) 100vw, (max-width: 1920px) 50vw, 25vw" fill className="object-contain" />
            </div>
          </div>
          <div className="basis-1/2 space-y-3 text-left">
            <div className="relative w-full" style={{ height: padLogo ? 20 : 30, marginBottom: padLogo ? 15 : 5 }}>
              <MotionImage src={logo} sizes="(max-width: 768px) 100vw, (max-width: 1920px) 50vw, 25vw" fill className="object-contain object-left" />
            </div>
            <div className="type-body sm:text-base">{heading}</div>
            <div data-bv-show="inline_rating" data-bv-product-id={productId} data-bv-redirect-url={redirectUrl}></div>
          </div>
        </div>
      </Link>
    </>
  );
};

// TODO: add more props
//const ShopButton: React.FC<{ image: IImage }> = ({ image }) => {
const ShopButton = ({ image, url, name, label, bvValue }) => {
  return (
    <div className="inline-block">
      <Button
        label={label}
        href={url}
        disabled={!url}
        buttonType={!url ? 'submit-primary' : 'link-primary'}
        onClick={evt => {
          evt.preventDefault();
          BV.pixel.trackConversion({
            type: 'Shop now',
            label: name,
            value: bvValue,
          });
          window.open(evt.currentTarget.href, '_blank');
        }}
        styleOverrides={{
          tailwind:
            'inline-block px-6 bg-xumoBerry disabled:bg-xumoSmoke cursor-pointer disabled:cursor-default disabled:text-xumoGrey disabled:drop-shadow-none',
          css: {
            backgroundColor: '',
            color: 'white',
          },
        }}
      />
    </div>
  );
};

const Dropdown = ({ options, filters, label, type, setFilters, renderLabel }) => {
  const [isOpen, setOpen] = useState(false);
  const [contentRef, { height: contentHeight }] = useMeasure();
  const [shown, setShown] = useState(false);
  const onClick = evt => {
    setShown(shown => !shown);
  };

  const ref = useRef();
  const dropdownRef = useRef();

  const toggleFilter = (option, value) => {
    setFilters(filters => {
      const current = filters[type];
      current[option] = value;

      const newFilters = {
        ...filters,
        [type]: current,
      };

      return newFilters;
    });
  };

  const motionProps = isOpen
    ? {
        display: 'block',
        height: contentHeight,
      }
    : {
        height: 0,
        transitionEnd: {
          display: 'none',
        },
      };

  return (
    <div className="relative z-20 flex flex-col border-b border-xumoBlack py-8">
      <button className="type-body-small weight-400 flex w-full text-left" onClick={() => setOpen(open => !open)} ref={ref}>
        <span data-i18n className="weight-500 flex grow">{label}</span>
        <span className="flex">{isOpen ? '-' : '+'}</span>
      </button>

      <motion.div className="relative overflow-clip" animate={motionProps}>
        <div ref={contentRef} className="pt-4">
          {options.map(option => {
            const isActive = filters[type][option];

            return (
              <button className="flex w-full justify-between rounded-xl py-2" onClick={() => toggleFilter(option, !isActive)}>
                <span data-i18n className="flex grow">{renderLabel ? renderLabel(option) : option}</span>
                <span className={`h-[20px] w-[20px] rounded-md ${isActive ? 'border-xumoTeal bg-xumoTeal' : 'border-xumoTeal bg-white'} border`} />
              </button>
            );
          })}
        </div>
      </motion.div>
    </div>
  );
};

const Contents = memo(() => {
  const { t } = useSanitizedTranslation();
  const [filters, setFilters] = useState({ size: {}, brand: {}, resolution: {} });

  const devices = devicesJSON
    .filter(device => {
      if (Object.values(filters.size).every(value => !value)) return true;

      const sizes = Object.entries(filters.size)
        .filter(entry => entry[1])
        .map(entry => parseInt(entry[0]));

      console.log(sizes, device.size);
      return sizes.includes(device.size);
    })
    .filter(device => {
      if (Object.values(filters.resolution).every(value => !value)) return true;
      const resolutions = Object.entries(filters.resolution)
        .filter(entry => entry[1])
        .map(entry => entry[0]);

      return resolutions.includes(device.resolution);
    })
    .filter(device => {
      if (Object.values(filters.brand).every(value => !value)) return true;
      const activeBrands = Object.entries(filters.brand)
        .filter(entry => entry[1])
        .map(entry => entry[0]);
      return activeBrands.includes(device.brand);
    });

  return (
    <div className="content-wrapper flex w-full flex-col gap-8 md:flex-row">
      <div className="relative z-10  mb-4 flex w-full flex-col md:w-[300px]">
        <h1 className="type-subheading mb-8" data-i18n>
          {t('Filter')}
        </h1>

        <Dropdown setFilters={setFilters} filters={filters} type="brand" label="Brand" options={['Element', 'Hisense', 'Pioneer']} />
        <Dropdown
          setFilters={setFilters}
          filters={filters}
          type="size"
          label="Size"
          options={[86, 75, 70, 65, 55, 50, 43, 40, 32, 24]}
          renderLabel={label => label + '"'}
        />
        <Dropdown setFilters={setFilters} filters={filters} type="resolution" label="Resolution" options={['HD', 'Full HD', '4K UHD']} />
      </div>
      <div className="flex grow">
        {devices.length > 0 && (
          <div className="relative grid min-h-[300px] w-full grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
            {devices.map(device => (
              <DeviceItem {...device} />
            ))}
          </div>
        )}
        {devices.length === 0 && <div className="type-body-bold w-full text-center">No devices that match these filters.</div>}
      </div>
    </div>
  );
});

export const ProductListingBlock: React.FC<ProductListingBlockProps> = memo(({ deviceId = 0 }) => {
  const ref = useRef();

  const isProduction = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';
  const url = isProduction
    ? 'https://apps.bazaarvoice.com/deployments/xumo/main_site/production/en_US/bv.js'
    : 'https://apps.bazaarvoice.com/deployments/xumo/main_site/production/en_US/bv.js';

  return (
    <>
      <ImageParallaxHero />
      <Script type="text/javascript" src={url} strategy="afterInteractive" />
      <motion.section className="relative z-40 flex  w-full justify-center bg-[#f5f7f8]" data-testid={'text-section'}>
        <div className="wrapper py-16">
          <Contents />
        </div>
      </motion.section>
    </>
  );
});

const ImageParallaxHero = ({ content, bg }) => {
  const { t } = useSanitizedTranslation();
  const { ref, progress: amt } = useInViewScroll([0, 1], ['start 100px', 'end 0vh']);
  const scrollY = useTransform(amt, [0, 1], [0, 350]);

  const [heroLoaded, setLoaded] = useState(false);
  const imageLoaded = () => setLoaded(true);
  const fadeInProps = {
    onLoadingComplete: imageLoaded,
    initial: { opacity: 0 },
    animate: { opacity: heroLoaded ? 1 : 0 },
  };

  return (
    <motion.section className="relative z-40 my-8 flex w-full justify-center bg-white" data-testid={'text-section'}>
      <div className="wrapper">
        <div className={`relative flex overflow-hidden rounded-3xl bg-xumoBlack`}>
          <div className="absolute left-0 top-0 h-[calc(100%+100px)] w-full">
            <MotionImage
              style={{ y: scrollY }}
              src={'https://xumo-cms.directus.app/assets/b968eec3-b2aa-4fe9-836a-3de1cfcda67d'}
              alt=""
              fill
              className="-top-8 object-cover brightness-90"
              sizes="100vw"
              {...fadeInProps}
            />
          </div>

          <div style={{ background: 'linear-gradient(to right, #000a 25%, #0007 60%, #0000 100%' }} className="absolute left-0 top-0 h-full w-full" />
          {/* <div className="w-3/4 text-center" dangerouslySetInnerHTML={{ __html: t(content) }} /> */}
          <div className="content-wrapper relative z-10 mt-48 w-2/3">
            <h2 data-i18n className="type-heading weight-300 pb-8 leading-[1.05] text-white">Bring Xumo TV home</h2>
            <p data-i18n className="type-body mb-4 leading-tight text-white">
              Discover new movies, enjoy your favorite comfort watches, tune into live sports and more, exclusively with Xumo devices.
            </p>
          </div>
        </div>
      </div>
    </motion.section>
  );
};
