'use client';

/* eslint-disable max-len */
import React, { useRef, useState, useCallback } from 'react';
import { useRouter } from 'next/router';
import { AnimatePresence, motion, useSpring, useTransform } from 'framer-motion';
import { MotionImage, SplitH2, useInViewScroll, useWindowSize, useScrolledTheme, useSanitizedTranslation } from 'ui';
import { makeVariants } from '../utils/makeVariants';
import { BazaarVoiceBlock } from 'ui/components/BazaarVoiceBlock';

export interface TestimonialBlockProps {}

const squiggleVariant = {
  hidden: {
    opacity: 0,
    transition: {
      when: 'afterChildren',
      delayChildren: 0,
      staggerChildren: 0.025,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.055,
      type: 'spring',
      stiffness: 90,
      damping: 23,
      restDelta: 0.001,
    },
  },
};

const swirlFallback = {
  src: '/static/images/testimonials-swirl.png',
};

const tvTestimonitals = [
  {
    text: '<p>Digital Trends: Best Buy expands its affordable Xumo TV lineup with Pioneer-branded models (Simon Cohen)</p>',
  },
  {
    text: `<p>Streaming Better: New Pioneer Xumo TVs Are Coming Soon To A Best Buy Near You (John Finn)</p>`,
  },
  {
    text: `<p>TV Technology: Xumo Launches Pioneer Xumo TVs (George Winslow)</p>`,
  },
];

const streamTestimonials = [
  {
    text: '<p>The Verge: Comcast and Charter have begun rolling out the first Xumo streaming box (Chris Welch)</p>',
  },
  {
    text: `<p>Gizmodo: Comcast and Charter's Streaming Box Has Arrived (Cheryl Eddy)</p>`,
  },
  {
    text: `<p>MediaPost: Xumo Stream Box Ramps Up: Competition For Roku, Amazon? (Wayne Friedman)</p>`,
  },
];

export const TestimonialsBlock: React.FC<TestimonialBlockProps> = ({}) => {
  const [[id, direction, random], setPage] = useState([0, 0]);

  const { t } = useSanitizedTranslation();

  const router = useRouter();
  const tvPage = router.asPath.indexOf('/products/xumo-tv') === 0;

  // TODO: cms
  const bgImage = swirlFallback;
  const backgroundColor = 'white';
  const primary = 'black';
  const heading = tvPage ? 'Xumo TV in the news' : 'Xumo Stream Box in the news';

  const testimonials = tvPage ? tvTestimonitals : streamTestimonials;

  const body = '';

  const Heading = SplitH2;

  const { ref, progress } = useInViewScroll([0, 1], ['start 50vh', 'end 50vh']);
  const movement = useSpring(progress, { stiffness: 400, damping: 90 });
  const y = useTransform(progress, [0, 1], ['0', '-15vh']);
  const opacity = useTransform(progress, [0, 0.4, 0.6, 1], [0.05, 1, 1, 0.05]);

  const variants = makeVariants.slideIn();

  const { width } = useWindowSize();
  const isMobile = width <= 719;

  const sectionRef = useRef();
  const { componentInView } = useScrolledTheme({
    ref,
    background: '#E0E7ED',
    accent: '#000000',
    text: '#000000',
  });

  const setTestimonial = useCallback(
    nid => {
      const newDirection = nid > id ? 1 : -1;

      const newId = (nid + testimonials.length) % testimonials.length;
      setPage([newId, newDirection, Math.random()]);
    },
    [id],
  );

  return (
    <>
      <div className="pointer-events-none fixed bottom-0 left-0 right-0 top-0 z-0 h-[120vh] overflow-hidden">
        <motion.div style={{ y, opacity }} className="container relative h-full max-lg:opacity-10">
          <AnimatePresence>
            {componentInView && (
              <MotionImage
                key="squiggle"
                initial="hidden"
                animate="show"
                exit="hidden"
                fill
                quality={90}
                variants={squiggleVariant}
                src={bgImage?.src}
                sizes="100vw"
                className="relative h-full w-full object-cover object-center lg:w-auto"
              />
            )}
          </AnimatePresence>
        </motion.div>
      </div>
      <motion.section className="w-full overflow-hidden sm:pb-0 sm:pt-0" data-testid={'testimonials-section'}>
        <motion.div
          ref={ref}
          initial="initial"
          whileInView="animate"
          viewport={{ once: true, margin: '-50% 0% -50% 0%' }}
          variants={variants}
          className={`wrapper relative relative mx-auto flex min-h-[400px] w-full flex-col items-center justify-center py-16 lg:py-32 lg:pb-64`}
        >
          <Heading variants={variants} content={heading} className={`type-heading z-10 flex flex-col`} />
          {body && <motion.div variants={variants} className="type-body py-4 lg:w-1/2" data-i18n dangerouslySetInnerHTML={{ __html: t(body) }} />}
          <div className="flex w-full items-center justify-center md:w-3/4 xl:w-2/3">
            <button className="relative -left-4 mx-2 max-md:hidden" onClick={() => setTestimonial(id - 1)}>
              <img src="/static/icons/arrow.svg" />
            </button>
            <div className={`grow-1 mt-8 flex w-full flex-col justify-center rounded-3xl bg-[#ffffffdd] p-8 lg:rounded-full`}>
              <motion.div layout className="relative flex h-[250px] items-center justify-center md:h-[150px] xl:h-[175px]">
                <AnimatePresence custom={direction}>
                  <motion.div
                    custom={direction}
                    initial={direction => ({
                      opacity: 0,
                      x: -20 * direction,
                    })}
                    animate={{
                      opacity: 1,
                      x: 0,
                    }}
                    exit={direction => ({
                      opacity: 0,
                      x: 20 * direction,
                      transition: { duration: 0.15 },
                    })}
                    key={'test-id-' + id + random}
                    className={'type-body-bold absolute px-2 lg:px-16'}
                    dangerouslySetInnerHTML={{ __html: t(testimonials[id].text) }}
                  />
                </AnimatePresence>
              </motion.div>
              <div>
                {testimonials.map((item, index) => (
                  <button aria-label={'view item ' + index} key={'test-button' + index} onClick={() => setTestimonial(index)} className={`p-8`}>
                    <span
                      className={`inline-block min-h-[12px] min-w-[12px] rounded-full border-none transition-all ${
                        index === id ? 'bg-xumoBerry' : 'bg-xumoOnyx'
                      }`}
                    />
                  </button>
                ))}
              </div>
            </div>
            <button className="relative -right-4 mx-2 rotate-180 max-lg:hidden" onClick={() => setTestimonial(id + 1)}>
              <img src="/static/icons/arrow.svg" />
            </button>
          </div>
        </motion.div>
      </motion.section>
    </>
  );
};
