/* eslint-disable sonarjs/cognitive-complexity */
import { Form, Formik, Field } from 'formik';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useState, useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import * as yup from 'yup';
import { XumoButton, Button, FormField, IFormField } from '.';
import { InputType, PageContext, useActiveElement } from '..';
import { ToastsContext } from '../contexts/ToastContext';
import { useDirectusAPI } from '../hooks';
import { CTA } from '../types';
import { chunkFormInput } from '../utils';
import ReactModal from 'react-modal';
import { motion, AnimatePresence } from 'framer-motion';

type FormProps = {
  formFields: IFormField[];
  CTA: CTA;
  formAction: handleSubmitProps;
  styleOverrides?: {
    form?: string;
    formField?: string;
    CTA?: {
      general?: string;
      disabledColor?: string;
      enabledColor?: string;
    };
  };
  showTextLengthCount?: boolean;
};

export type handleSubmitProps = {
  submissionEndpoint: string;
  successMessage?: string;
  errorMessage?: string;
  onClick?: () => void;
};

const ErrorText = ({ text, id }: { text?: string; id: string }) => {
  return (
    <p id={id} className="relative bottom-0 left-4 text-sm italic text-xumoBerry">
      {text}
    </p>
  );
};

const TextLengthCount = ({ textLength, maxLength }: { textLength: number; maxLength?: number }) => (
  <p className={`${textLength > 120 ? 'text-xumoBerry' : 'text-xumoCharcoal'} justify-self-end px-4 text-sm`}>
    {textLength} {maxLength && `/ ${maxLength}`}
  </p>
);

const modalVariants = {
  initial: { scale: 1.1, opacity: 0 },
  animate: { scale: 1, opacity: 1 },
  exit: { scale: 0.9, opacity: 0 },
};

export const XumoForm = ({ formFields, CTA, formAction, styleOverrides, showTextLengthCount = true }: FormProps) => {
  const [modalStatus, setModalStatus] = useState<string | undefined>();
  const focusedElement = useActiveElement();
  const router = useRouter();
  const api = useDirectusAPI();
  const { addToast } = useContext(ToastsContext);
  const { pageCtx } = useContext(PageContext);
  const { host } = pageCtx;

  // useEffect(() => {
  //   setModalStatus('LOADING')
  //   window.modalLoading = () => setModalStatus('LOADING')
  //   window.modalSuccess = () => setModalStatus('SUCCESS')
  // }, [])

  const sanitizedFieldNameMap = useMemo<Record<string, string>>(
    () => formFields.reduce((acc, { name }) => ({ ...acc, [name]: name.toLowerCase().replace(/ /g, '_') }), {}),
    [formFields],
  );
  const initFormValues = useMemo(
    () =>
      formFields.reduce(
        (acc: { [key: string]: string }, { name }) => {
          acc[sanitizedFieldNameMap[name]] = '';
          return acc;
        },
        { phone_number: '' },
      ),
    [sanitizedFieldNameMap, formFields],
  );

  const [ariaIds, setAriaIds] = useState<{ [key: string]: string }>({});
  useEffect(() => {
    const newAriaIds = formFields.reduce((acc: { [key: string]: string }, { name }) => {
      acc[sanitizedFieldNameMap[name]] = uuid();
      return acc;
    }, {});

    setAriaIds(newAriaIds);
  }, [formFields, sanitizedFieldNameMap]);

  const validationSchema = yup.object(
    // Dynamically generate validation schema based on formInput type
    formFields?.reduce((acc: { [key: string]: yup.StringSchema }, { name, type, placeholder, customValidation }) => {
      let validation: yup.StringSchema;
      if (customValidation) {
        validation = customValidation;
      } else {
        switch (type) {
          case InputType.Textarea:
            validation = yup.string().required(`${placeholder} is required`).max(120, `${placeholder} can be at most 120 characters`);
          case InputType.Email:
            validation = yup.string().required(`${placeholder} is required`).email(`${placeholder} must be a valid email`);
          case InputType.Tel:
            validation = yup
              .string()
              .required(`${placeholder} is required`)
              .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, `${placeholder} must be a valid phone number`);
          case InputType.Radio:
          default:
            validation = yup.string().required(`${placeholder} is required`);
        }
      }

      acc[sanitizedFieldNameMap[name]] = validation;
      return acc;
    }, {}),
  );

  const handleSubmit = async (formData: Record<string, string>) => {
    try {
      setModalStatus('LOADING');
      const res = await fetch(formAction?.submissionEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          slug: host + router.asPath,
        }),
      });

      if (res.status === 200) {
        setModalStatus('SUCCESS');
      }
      return res.status;
    } catch (err) {
      console.error(err);
      setModalStatus(undefined);
      addToast({ id: uuid(), title: '', description: formAction?.errorMessage || "Sorry, we couldn't submit your request.", type: 'error' });
    }
  };

  return (
    <>
      <Formik
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initFormValues}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true);
          actions.validateForm();
          formAction.onClick && formAction.onClick();
          await handleSubmit(values);
          actions.resetForm();
        }}
      >
        {({ values, errors, dirty, isValid, isSubmitting, handleChange, handleSubmit }) => {
          const keys = Object.keys(values);

          let hasEmptyFields = false;

          for (let i = 0; i < keys.length; i++) {
            if (values[keys[i]] === '' && keys[i] !== 'phone_number') {
              hasEmptyFields = true;
              break;
            }
          }

          const isDisabled = isSubmitting || !dirty || hasEmptyFields;

          return (
            <Form key={'lead-gen-form'} className={`form z-20 flex w-full flex-col [filter:revert]  ${styleOverrides?.form}`} onSubmit={handleSubmit}>
              {chunkFormInput(formFields, 2).map((row: IFormField | IFormField[], index) => {
                if (Array.isArray(row)) {
                  return (
                    <div key={'form' + index} className="w-full">
                      <div className="flex w-full flex-col justify-between gap-4 sm:flex-row">
                        {row.map(({ type, name, placeholder, width }: IFormField, j) => {
                          const sanitizedName = sanitizedFieldNameMap[name];
                          return (
                            <FormField
                              key={`${index}-${j}`}
                              name={sanitizedName}
                              placeholder={placeholder}
                              type={type}
                              handleChange={handleChange}
                              value={values[name]}
                              focusedElementId={focusedElement?.id}
                              styleOverride={styleOverrides?.formField}
                              width={width}
                              errorId={ariaIds[sanitizedName] + `-${j}`}
                            />
                          );
                        })}
                      </div>
                      <div className="flex w-full">
                        {row.map(({ name }, j) => (
                          <ErrorText id={ariaIds[sanitizedFieldNameMap[name]] + `-${j}`} key={j} text={errors[sanitizedFieldNameMap[name]]} />
                        ))}
                      </div>
                    </div>
                  );
                }
                const sanitizedName = sanitizedFieldNameMap[row.name];
                const ariaId = ariaIds[sanitizedName];
                return (
                  <React.Fragment key={'form-field' + index}>
                    <FormField
                      name={sanitizedName}
                      placeholder={row.placeholder}
                      type={row.type}
                      handleChange={handleChange}
                      value={values[sanitizedName]}
                      focusedElementId={focusedElement?.id}
                      styleOverride={styleOverrides?.formField}
                      width={row?.width}
                      errorId={ariaId}
                    />
                    <div className="flex w-full flex-row justify-between py-2">
                      <ErrorText id={ariaId} text={errors[sanitizedName] ?? ''} />
                      {row.type === InputType.Textarea && showTextLengthCount && <TextLengthCount textLength={values[sanitizedName].length} maxLength={120} />}
                    </div>
                  </React.Fragment>
                );
              })}
              <div className="absolute bottom-0 right-0 opacity-0">
                <Field id="phone_number" name="phone_number" type="tel" onChange={handleChange} />
              </div>
              <XumoButton submit isDisabled={isDisabled} label={CTA.label} />
            </Form>
          );
        }}
      </Formik>

      <ReactModal isOpen={!!modalStatus} style={{ overlay: { zIndex: 1000 }, content: { inset: '0', background: 'transparent', padding: 0, margin: 0 } }}>
        <div className={'relative flex h-full w-full flex-col items-center justify-center bg-[#00000088]'}>
          <AnimatePresence>
            {modalStatus === 'LOADING' && (
              <motion.div
                key="modal-loading"
                initial="initial"
                animate="animate"
                exit="exit"
                variants={modalVariants}
                className="absolute m-2 flex rounded-lg bg-white p-4"
              >
                Sending your request...
              </motion.div>
            )}
            {modalStatus === 'SUCCESS' && (
              <motion.div
                key="modal-success"
                initial="initial"
                animate="animate"
                exit="exit"
                variants={modalVariants}
                className="absolute m-4 flex w-full flex-col rounded-lg bg-white p-4 md:w-[450px]"
              >
                <h2 className="type-heading mb-2" data-i18n>
                  Thank you!
                </h2>
                <p data-i18n>
                  Thank you for contacting us! Our team has received your inquiry and will connect with you after reviewing and assessing the potential business
                  opportunity. Thank you!
                </p>
                <Button
                  buttonType="submit-primary"
                  label={'Okay'}
                  onClick={() => setModalStatus(undefined)}
                  styleOverrides={{
                    css: { backgroundColor: '#155772', color: '#FFF', marginTop: 15 },
                    tailwind: '',
                  }}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </ReactModal>
    </>
  );
};
