import { DirectusChannelSearchBlock } from 'config';
import { ChannelSearchBlockProps } from '../../../components';
import websiteContent from 'config/xumo-website-output.json';
import startCase from 'lodash.startcase';
import groupBy from 'lodash.groupby';

const _ = require('lodash');

function toTitleCase(input) {
  const spaced = input.replace(/_/g, ' ');
  const smallWords = ['and', 'or', 'of', 'in', 'on', 'the'];

  const words = spaced.split(' ');

  const processedWords = words.map((word, index) => {
    const titleCased = startCase(_.toLower(word));
    const hasVowels = /[aeiou]/i.test(titleCased);

    if (!hasVowels) {
      return word.toUpperCase();
    }

    if (smallWords.includes(word.toLowerCase()) && index !== 0) {
      return word.toLowerCase();
    }

    return titleCased;
  });

  return processedWords.join(' ');
}

export const mapDirectusChannelSearchBlock = (block: DirectusChannelSearchBlock): ChannelSearchBlockProps => {
  const categories = groupBy(websiteContent.appContent, 'category');

  const genres = Object.entries(categories).reduce((acc, value) => {
    const genre = {
      heading: toTitleCase(value[0]),
      ...groupBy(value[1], 'type'),
    };

    return [...acc, genre];
  }, []);

  return {
    anchor: block.anchor,
    heading: block.heading,
    searchPlaceholder: block.search_bar_placeholder,
    backgroundColor: block.background_color ?? '#fff',
    genres,
  };
};
