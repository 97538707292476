import React from 'react';
import { MagnifyingGlass } from './';
import { useSanitizedTranslation } from '../hooks';

type SearchBarProps = {
  value: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeHolder: string;
};

export const SearchBar = ({ value, handleChange, placeHolder }: SearchBarProps) => {
  const { t } = useSanitizedTranslation();

  return (
    <div className="relative mt-8 flex w-full flex-row items-center justify-center">
      <input
        className="type-body w-[64%] rounded-md border border-2 border-solid border-xumoIce p-8 text-center focus:border-xumoBlack"
        type="text"
        id="search-bar"
        onChange={handleChange}
        value={value}
      />
      {value === '' && (
        <label htmlFor="search-bar" className={`pointer-events-none absolute flex h-full items-center justify-center whitespace-nowrap`}>
          <p className={`type-body overflow-hidden text-ellipsis whitespace-nowrap text-center `} data-i18n={placeHolder}>
            {t(placeHolder)}
          </p>
        </label>
      )}
    </div>
  );
};
