import classNames from 'classnames';
import Link from 'next/link';
import Image from 'next/image';

export const ButtonRow = ({ buttons }) => {
  return (
    <div className="align-start flex justify-start space-x-8">
      {buttons.map(button => (
        <XumoButton {...button} />
      ))}
    </div>
  );
};

export const XumoButton = ({ submit, children, light, href, onClick, label, target, disabled }) => {
  const style = light ? 'bg-white text-black' : 'bg-black text-white';

  const classes = classNames(
    'btn',
    'cursor-pointer',
    'rounded-md',
    'border-2',
    'border-black',
    'text-[16px]',
    'weight-600',
    style,
    'outline',
    'outline-2',
    'outline-offset-2',
    'outline-transparent',
    'hover:outline-xumoTeal',
    'transition-colors',
    'px-8',
    'py-4',
    'inline-block',
    'whitespace-nowrap',
    'disabled:bg-xumoOnyx disabled:text-xumoCharcoal',
  );

  return submit ? (
    <button data-i18n onClick={onClick} className={classes} disabled={disabled}>
      {label || children}
    </button>
  ) : (
    <Link data-i18n onClick={onClick} href={href || ''} className={classes} target={target} disabled={disabled}>
      {label || children}
    </Link>
  );
};

export const ImageButton = ({ children, image, hoverImage, href, onClick, label, target, disabled }) => {
  const classes = classNames('btn', 'cursor-pointer', 'inline-block', 'whitespace-nowrap');

  return (
    <Link onClick={onClick} href={href || ''} className={classes} aria-label={label}>
      <Image {...image} />
    </Link>
  );
};
