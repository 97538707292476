//@ts-nocheck
/* eslint-disable max-len */
import { motion } from 'framer-motion';
import React, { useRef } from 'react';
import { CTA, IImage } from '../types/common';
import { useSanitizedTranslation, useWindowSize } from '../hooks';
import { makeVariants } from '../utils/makeVariants';
import { Button, ImageWithBackground, MotionImage, SplitDiv } from './';
import { useScrolledTheme } from 'ui';
import Head from 'next/head';
import { useRouter } from 'next/router';

export interface CtaColumnsProps {
  bgColor: string;
  textColor: string;
  accent: string;
  columns?: CtaColumn[];
  heading?: string;
  body?: string;
  columnAlign?: string;
  headingAlign?: string;
  ctaAlign?: string;
  iconSize?: 'small' | 'medium' | 'large';
  withDivider?: boolean;
  imageBgFill?: boolean;
  largeColHeaders?: boolean;
}

export interface CtaColumn {
  icon?: IImage;
  iconBackground?: IImage;
  superText?: string;
  heading?: string;
  body?: string;
  cta?: CTA;
  card?: boolean;
}

export const CtaColumns: React.FC<CtaColumnsProps> = ({
  bgColor,
  textColor,
  accent,
  columns,
  heading,
  body,
  columnAlign,
  headingAlign,
  ctaAlign,
  iconSize = 'small',
  withDivider = true,
  imageBgFill = false,
  largeColHeaders = false,
}) => {
  const { t } = useSanitizedTranslation();
  const variants = makeVariants.slideIn();
  const { width } = useWindowSize();

  const ref = useRef();
  const { componentInView } = useScrolledTheme({
    ref,
    background: bgColor,
    accent: '#000000',
    text: textColor,
  });

  const getItemListJsonLd = () => {
    const obj = {
      '@context': 'https://schema.org',
      '@type': 'ItemList',
      itemListElement: columns.map(({ heading, body, icon }, index) => {
        return {
          '@type': 'ListItem',
          position: index + 1,
          name: heading,
          description: body,
          image: icon,
        };
      }),
    };

    return {
      __html: JSON.stringify(obj),
    };
  };

  const hasIntroCopy = !!heading || !!body;

  const router = useRouter();
  const isAdvertising = router.asPath.indexOf('/advertising') === 0;
  const adStyles = isAdvertising ? { style: { background: 'white', borderRadius: 15 } } : {};

  return (
    <motion.section ref={ref} className="wrapper relative w-full" data-testid={'cta-columns-section'}>
      <div className={`z-1 relative ${hasIntroCopy ? 'py-16' : 'py-16 lg:py-24'}`} {...adStyles}>
        {heading && (
          <motion.h2
            variants={variants}
            initial="initial"
            whileInView="animate"
            className="type-heading z-10"
            style={{
              color: textColor,
              textAlign: headingAlign === 'left' ? 'left' : 'center',
            }}
            data-i18n={heading}
          >
            {t(heading)}
          </motion.h2>
        )}
        {body && (
          <motion.div
            variants={variants}
            initial="initial"
            whileInView="animate"
            className="allow-html-styles type-body w-full pt-8 md:w-1/2"
            dangerouslySetInnerHTML={{ __html: t(body) }}
            style={{
              textAlign: headingAlign,
              color: textColor,
            }}
            data-i18n={body}
          />
        )}
        <motion.div
          initial="initial"
          whileInView="animate"
          variants={variants}
          viewport={{ once: true, margin: '-25% 0% -25% 0%' }}
          className={`flex w-full flex-col flex-wrap items-start  gap-2 pt-8 md:flex-row md:justify-between md:gap-4`}
        >
          {
            // eslint-disable-next-line sonarjs/cognitive-complexity
            columns.map(({ icon, iconBackground, body, heading, superText, cta }, i) => {
              return (
                <motion.div
                  variants={variants}
                  viewport={{ once: true }}
                  className={`flex min-w-[110px] shrink grow basis-0 ${
                    columns.length === 4 ? 'md:basis-2/5 lg:basis-0' : ''
                  } flex-col  items-start  gap-2  max-lg:w-full max-md:mb-6 max-md:first:mt-0
                  max-[720px]:self-center
                  ${heading ? 'justify-between md:justify-start' : 'justify-start'}`}
                  key={body || heading}
                >
                  <div className="relative mb-4 w-full overflow-hidden rounded-xl">
                    {icon &&
                      (imageBgFill || iconBackground?.src ? (
                        <ImageWithBackground image={icon} backgroundImage={iconBackground} size={'small'} accent={bgColor} />
                      ) : (
                        <MotionImage
                          className={`relative shrink-0 translate-x-0 object-cover opacity-100 ${!withDivider && 'self-start'} ${
                            iconSize === 'small' ? 'w-[75px]' : iconSize === 'medium' ? 'w-2/5' : 'w-full'
                          }`}
                          style={{ aspectRatio: isAdvertising ? 16 / 9 : '' }}
                          sizes="(max-width: 768px) 100vw, (max-width: 1600px) 50vw, 33vw"
                          src={icon.src}
                          alt={icon.alt}
                          fill
                        />
                      ))}
                  </div>
                  <div className={`flex flex-col items-start max-md:justify-center max-md:justify-self-end max-sm:w-full max-sm:pl-0 md:w-3/4 lg:w-full`}>
                    {superText && (
                      <SplitDiv
                        content={superText}
                        className="allow-html-styles type-body md:w-4/5 md:whitespace-nowrap"
                        variants={variants}
                        style={{ textAlign: columnAlign, color: textColor }}
                        data-i18n={superText}
                      />
                    )}
                    {heading && (
                      <motion.div
                        className={`type-subheading-small my-2 w-full max-md:px-4 md:w-4/5`}
                        style={{ color: textColor }}
                        variants={variants}
                        data-i18n={heading}
                        dangerouslySetInnerHTML={{ __html: t(heading) }}
                      />
                    )}
                    {body && (
                      <motion.div
                        content={body}
                        className={`allow-html-styles type-body-base list-outside list-disc max-md:px-4 lg:text-md ${
                          body.length < 25 ? 'md:whitespace-nowrap' : ''
                        } w-full items-start md:w-4/5`}
                        variants={variants}
                        style={{ color: textColor }}
                        data-i18n={body}
                        dangerouslySetInnerHTML={{ __html: t(body) }}
                      />
                    )}
                    {cta && (
                      <Button
                        label={cta.label}
                        href={cta.href}
                        buttonType="link-primary"
                        key={cta.label}
                        styleOverrides={{
                          css: { backgroundColor: accent },
                          tailwind: `${ctaAlign === 'left' ? 'align-self-left' : ''} mt-5 w-48 h-8 py-5 text-sm leading-[14px]	`,
                        }}
                        data-i18n={cta.label}
                      />
                    )}
                  </div>
                </motion.div>
              );
            })
          }
        </motion.div>
        {heading?.indexOf('We have more content') >= 0 && (
          <span data-i18n className="type-body inline-block pt-16 text-[10px] leading-tight text-black">
            *Methodology: TVision reports on attention % as a the percentage of ad impressions where a viewer was watching the TV screen for two or more
            seconds. This is measured within their panel of approximately 5,000 households nationwide using sensors to measure who is in the room and if their
            eyes are on screen. Source: TVision Total View (based on 2-year lookback ending in January 2024 for P2+ for selected TV apps)​
          </span>
        )}
      </div>
    </motion.section>
  );
};
