import { motion, useSpring, useTransform, useInView, AnimatePresence, cubicBezier, useAnimate, stagger } from 'framer-motion';
import { useRef, useState, useEffect, useContext } from 'react';
import { useInViewScroll, MotionImage } from 'ui';
import { useRouter } from 'next/router';
import Link from 'next/link';

const calculateTimeLeft = targetDate => {
  const difference = new Date(targetDate) - new Date();

  let timeLeft = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
};

const useCountdown = targetDate => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(calculateTimeLeft(targetDate));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [targetDate]);

  return [timeLeft.days, timeLeft.hours, timeLeft.minutes, timeLeft.seconds].map(val => String(val).padStart(2, '0'));
};

const Digit = ({ value }) => {
  return (
    <span className="relative h-full w-5 overflow-clip rounded-md border border-[#fff6] text-white lg:w-6">
      <AnimatePresence>
        <motion.span
          key={value}
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 10 }}
          transition={{ ease: 'circInOut' }}
          className="absolute inline-block flex h-full w-full items-center justify-center px-[2px] lg:px-1"
        >
          {value}
        </motion.span>
      </AnimatePresence>
    </span>
  );
};

const date = new Date('11-01-2024');

export const Banner = () => {
  const { ref, progress: amt } = useInViewScroll([0, 1], ['start 100vh', 'end 0vh']);
  const scrollY = useTransform(amt, [0, 1], [0, 50]);

  const [slide, setSlide] = useState(0);

  const inView = useInView(ref, { once: true, margin: '-30% 0% -30% 0%' });

  const [days, hours, minutes, seconds] = useCountdown(date);

  const backgrounds = ['linear-gradient(to bottom, #0166fe, #013d7c)', 'linear-gradient(to top, rgb(20, 13, 19), rgb(91, 46, 79))'];

  const pathname = useRouter().asPath;

  const isTv = pathname.indexOf('products/xumo-tv') >= 0;

  const ctaUrl = isTv
    ? 'https://support.xumo.com/hc/en-us/articles/22512230615700-Peacock-app-on-Xumo-devices'
    : 'https://support.xumo.com/hc/en-us/articles/22512230615700-Peacock-app-on-Xumo-devices';

  const product = isTv ? 'Xumo TV' : 'Xumo Stream Box';

  return (
    <motion.section
      initial={{ opacity: 0, y: 40 }}
      animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 40 }}
      transition={{ ease: [0, 1, 1, 1] }}
      ref={ref}
      className="relative z-40 w-full bg-white"
      data-testid={'text-section'}
    >
      <div className="wrapper my-2">
        <Link
          href={ctaUrl}
          aria-labelledby="banner-cta-label"
          className={`group relative block cursor-pointer rounded-3xl outline outline-2 outline-offset-2 outline-transparent transition-colors hover:outline-black`}
          style={{ background: 'black' }}
        >
          <motion.div
            initial={{
              x: 0,
              opacity: 1,
            }}
            animate={{
              x: slide === 0 ? 0 : -10,
              opacity: slide === 0 ? 1 : 0,
              zIndex: slide === 0 ? 5 : 0,
            }}
            transition={{ ease: 'cubicInOut' }}
            className="flex flex-col md:flex-row"
          >
            <div className="xl:min-w-auto flex shrink-0 pl-5 text-white md:min-w-[12rem] md:items-center md:justify-center md:pl-0 lg:min-w-[16rem] lg:p-4 xl:min-h-[200px] xl:p-6 ">
              <div className="relative h-[65px] w-[162px]">
                <MotionImage
                  src="/static/images/refresh/peacock-logo-white.png"
                  priority
                  fill
                  sizes="100vw"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: inView ? 1 : 0 }}
                  transition={{ delay: 0 }}
                  className="object-fit"
                />
              </div>
            </div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: inView ? 1 : 0 }}
              transition={{ delay: 0.2 }}
              className="max-md:content-wrapper type-body flex min-h-full min-w-[0px] flex-col items-start justify-center pt-0 text-white md:py-4 md:pr-6"
            >
              <span className="mb-1 block text-md">
                Subscribe on your Xumo device to start streaming new movies, including Wicked, at no additional cost for 6 months.
              </span>
              <div className="flex flex-col items-start py-1 md:py-2 lg:py-3 xl:py-4 ">
                <div className="h-0.5 w-[5.875rem] bg-white" />
              </div>
              <div className="weight-300 mt-2 justify-start text-left text-xs">Limited time offer. $7.99/mo (+tax) after offer ends. Cancel anytime.</div>
            </motion.div>
            <div className="relative hidden min-h-full md:flex lg:min-w-[38%]">
              <div className="pointer-events-none absolute right-0 top-0 h-full w-full overflow-clip rounded-r-3xl">
                <div className="relative h-full w-full">
                  <MotionImage src="/static/images/refresh/wicked-bg.png" priority fill={true} />
                </div>
              </div>
            </div>
          </motion.div>
        </Link>
      </div>
    </motion.section>
  );
};

export const BannerNBA = () => {
  const { ref, progress: amt } = useInViewScroll([0, 1], ['start 100vh', 'end 0vh']);
  const scrollY = useTransform(amt, [0, 1], [0, 50]);

  const [slide, setSlide] = useState(0);
  // const intervalId = useRef(-1)

  // useEffect(() => {
  //   intervalId.current = setInterval(() => {
  //     setSlide(slide => (slide+1)%2);
  //   }, 10000);

  //   return () => clearInterval(intervalId.current);
  // }, [slide]);

  // const goto = (num) => {
  //   clearInterval(intervalId.current)
  //   setSlide(num)
  // }

  const inView = useInView(ref, { once: true, margin: '-30% 0% -30% 0%' });

  const [days, hours, minutes, seconds] = useCountdown(date);

  const backgrounds = ['linear-gradient(to bottom, #0166fe, #013d7c)', 'linear-gradient(to top, rgb(20, 13, 19), rgb(91, 46, 79))'];

  const pathname = useRouter().asPath;

  const isTv = pathname.indexOf('products/xumo-tv') >= 0;

  const ctaUrl = isTv
    ? 'https://www.xumo.com/offer/nba?intcmp=ILC:XTVMP:bufl:0:INB:NBA:offer:910003'
    : 'https://www.xumo.com/offer/nba?intcmp=ILC:XSBMP:bufl:0:INB:NBA:offer:910004';

  const product = isTv ? 'Xumo TV' : 'Xumo Stream Box';

  return (
    <motion.section
      initial={{ opacity: 0, y: 40 }}
      animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 40 }}
      transition={{ ease: [0, 1, 1, 1] }}
      ref={ref}
      className="relative z-40 w-full bg-white"
      data-testid={'text-section'}
    >
      <div className="wrapper my-2">
        <Link
          href={ctaUrl}
          aria-labelledby="banner-cta-label"
          className={`group relative block cursor-pointer rounded-3xl outline outline-2 outline-offset-2 outline-transparent transition-colors hover:outline-xumoTeal`}
          style={{ background: backgrounds[slide] }}
        >
          {/* container */}
          <motion.div
            initial={{
              x: 0,
              opacity: 1,
            }}
            animate={{
              x: slide === 0 ? 0 : -10,
              opacity: slide === 0 ? 1 : 0,
              zIndex: slide === 0 ? 5 : 0,
            }}
            transition={{ ease: 'cubicInOut' }}
            className="flex flex-col md:flex-row"
          >
            <div className="xl:min-w-auto flex shrink-0 p-6 pr-6 text-white md:min-w-[12rem] lg:p-8 xl:p-12 ">
              <div className="relative aspect-[2400/1560] h-full  min-h-[50px] w-auto max-md:max-h-[60px] md:min-h-[60px] xl:min-h-[80px]">
                <MotionImage
                  src="/static/images/refresh/nba-league-pass-dark.png"
                  priority
                  fill
                  sizes="100vw"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: inView ? 1 : 0 }}
                  transition={{ delay: 0 }}
                  className="object-fit"
                />
              </div>
            </div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: inView ? 1 : 0 }}
              transition={{ delay: 0.2 }}
              className="max-md:content-wrapper type-body flex min-h-full min-w-[0px] flex-col items-start justify-center pt-0 text-white md:py-4 md:pr-6"
            >
              <span className="mb-1 block text-lg lg:text-xl xl:text-2xl">Your home for streaming hoops</span>
              <span id="banner-cta-label" className="text-sm lg:text-base">
                Stream live game action from your {product} with NBA League Pass&nbsp;for&nbsp;$16.99/month.
                <br />
                <span className="mt-2 block underline decoration-[#fff6] decoration-2 underline-offset-4 transition-colors group-hover:decoration-[#fffc]">
                  Start&nbsp;your&nbsp;7&#8209;day&nbsp;free&nbsp;trial&nbsp;today
                </span>
              </span>

              <div className="weight-300 mt-2 justify-start text-left text-xs text-[#fff7]">Taxes and fees extra. Blackout and other restrictions apply.</div>
            </motion.div>
            <div className="relative hidden min-h-full md:flex lg:min-w-[38%]">
              <div className="pointer-events-none absolute bottom-0 right-0 aspect-[2] w-full overflow-clip rounded-3xl">
                <div className="relative h-full w-full transition-all will-change-transform group-hover:scale-[1.02]">
                  <MotionImage
                    style={{ y: scrollY }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: inView ? 1 : 0 }}
                    transition={{ delay: 0.4 }}
                    src="/static/images/refresh/nba-takeover.png"
                    priority
                    fill
                    sizes="100vw"
                    className="object-fit"
                  />
                </div>
              </div>
            </div>
          </motion.div>
          {/*container*/}

          {/* container */}
          {/* <motion.div 
            initial={{
              x: 0,
              opacity: 1
            }}
            animate={{
              x: slide === 1 ? 0 : -10,
              opacity: slide === 1 ? 1 : 0,
              zIndex: slide === 1 ? 5 : 0
            }}
            className="absolute flex top-0 left-0 w-full h-full">
            <div className="p-6 lg:p-8 xl:p-16 xl:pr-8 flex text-white">
              <div className="relative h-full min-h-[60px] text-lg xl:text-xl weight-600 gap-2 flex">
                <div className="text-center flex flex-col items-center">
                  <div className="flex grow gap-[2px] justify-center items-center">
                    <Digit value={days[0]} />
                    <Digit value={days[1]} />
                  </div>
                  <div className="flex shrink text-[#fff6] text-xs weight-300">D</div>
                </div>
                <div className="text-center flex flex-col items-center">
                  <div className="flex grow gap-[2px] justify-center items-center">
                    <Digit value={hours[0]} />
                    <Digit value={hours[1]} />
                  </div>
                  <div className="flex shrink text-[#fff6] text-xs weight-300">H</div>
                </div>
                <div className="text-center flex flex-col items-center">
                  <div className="flex grow gap-[2px] justify-center items-center">
                    <Digit value={minutes[0]} />
                    <Digit value={minutes[1]} />
                  </div>
                  <div className="flex shrink text-[#fff6] text-xs weight-300">M</div>
                </div>
                <div className="text-center flex flex-col items-center">
                  <div className="flex grow gap-[2px] justify-center items-center">
                    <Digit value={seconds[0]} />
                    <Digit value={seconds[1]} />
                  </div>
                  <div className="flex shrink text-[#fff6] text-xs weight-300">S</div>
                </div>
              </div>
            </div>
            <motion.div 
              initial={{opacity: 0}}
              animate={{opacity: inView ? 1 : 0}}
              transition={{delay: 0.2}}
              className="pr-4 py-4 flex grow min-h-full items-center text-white type-body">
              <span>Don’t miss these Black Friday offers! <span className="underline decoration-2 decoration-[#fff6] underline-offset-4 group-hover:decoration-[#fffc] transition-colors">Shop&nbsp;deals</span></span>
            </motion.div>
            <div className="flex md:min-w-[40%] min-h-full relative">
              <div className="aspect-[2] w-full absolute bottom-0 right-0 overflow-clip rounded-3xl pointer-events-none">
                <div className="group-hover:scale-[1.02] transition-all relative w-full h-full">
                  <MotionImage
                    style={{y:scrollY}}
                    initial={{opacity: 0}}
                    animate={{opacity: inView ? 1 : 0}}
                    transition={{delay: 0.4}}
                    src="/static/images/refresh/black-friday-devices.png"
                    priority
                    fill
                    sizes="100vw"
                    className="object-fit" />
                </div>
              </div>
            </div>
          </motion.div> */}
          {/*container*/}
        </Link>
      </div>
    </motion.section>
  );
};

export const Banner2 = () => {
  const { ref, progress: amt } = useInViewScroll([0, 1], ['start 100vh', 'end 0vh']);
  const scrollY = useTransform(amt, [0, 1], [0, 50]);

  const [slide, setSlide] = useState(0);
  // const intervalId = useRef(-1)

  // useEffect(() => {
  //   intervalId.current = setInterval(() => {
  //     setSlide(slide => (slide+1)%2);
  //   }, 10000);

  //   return () => clearInterval(intervalId.current);
  // }, [slide]);

  // const goto = (num) => {
  //   clearInterval(intervalId.current)
  //   setSlide(num)
  // }

  const inView = useInView(ref, { once: true, margin: '-30% 0% -30% 0%' });

  const backgrounds = ['linear-gradient(to bottom, #1131f5, #000062)', 'linear-gradient(to top, rgb(20, 13, 19), rgb(91, 46, 79))'];

  const pathname = useRouter().asPath;

  const isTv = pathname.indexOf('products/xumo-tv') >= 0;

  const ctaUrl = isTv ? 'https://xumo.com' : 'https://www.xumo.com';

  const product = isTv ? 'Xumo TV' : 'Xumo Stream Box';

  return (
    <motion.section
      initial={{ opacity: 0, y: 40 }}
      animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 40 }}
      transition={{ ease: [0, 1, 1, 1] }}
      ref={ref}
      className="relative z-40 mt-16 w-full bg-white"
      data-testid={'text-section'}
    >
      <div className="wrapper my-2">
        <Link
          href={ctaUrl}
          aria-labelledby="banner-cta-label"
          className={`group relative block cursor-pointer overflow-clip rounded-3xl outline outline-2 outline-offset-2 outline-transparent transition-colors hover:outline-xumoTeal`}
          style={{ background: backgrounds[slide] }}
        >
          {/* container */}
          <div className="absolute h-[calc(100%+50px)] w-full">
            <div className="pointer-events-none absolute -top-[50px] right-0 h-full w-full overflow-clip rounded-3xl">
              <MotionImage
                style={{ y: scrollY }}
                initial={{ opacity: 0 }}
                animate={{ opacity: inView ? 1 : 0 }}
                transition={{ delay: 0.4 }}
                src="/static/images/refresh/max-quilt-bg.jpg"
                priority
                fill
                sizes="100vw"
                className="object-cover"
              />
            </div>
          </div>
          <motion.div
            initial={{
              x: 0,
              opacity: 1,
            }}
            animate={{
              x: slide === 0 ? 0 : -10,
              opacity: slide === 0 ? 1 : 0,
              zIndex: slide === 0 ? 5 : 0,
            }}
            transition={{ ease: 'cubicInOut' }}
            style={{ background: 'linear-gradient(to bottom, #0006, #000f' }}
            className="z-5 relative flex flex-col md:flex-row"
          >
            <div className="xl:min-w-auto flex shrink-0 items-center p-6 pr-6 text-white lg:p-8 xl:p-12">
              <div className="relative aspect-[366/100] h-full min-h-[40px] w-auto max-xl:max-h-[50px] max-lg:max-h-[40px] lg:min-h-[50px]">
                <MotionImage
                  src="/static/images/refresh/Max_logo.png"
                  priority
                  fill
                  sizes="100vw"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: inView ? 1 : 0 }}
                  transition={{ delay: 0 }}
                  className="object-fit"
                />
              </div>
            </div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: inView ? 1 : 0 }}
              transition={{ delay: 0.2 }}
              className="max-md:content-wrapper type-body flex min-h-full min-w-[0px] grow flex-col items-start justify-center pt-0 text-white md:py-4 md:pr-6"
            >
              <span className="mb-1 block text-lg lg:text-xl xl:text-2xl">Black Friday Deal</span>
              <span id="banner-cta-label" className="text-sm lg:text-base">
                70% off! <span className="line-through decoration-[#00f0ff] decoration-2">$9.99/mo</span> $2.99 for 6 months.
                <br />
                <span className="mt-2 block underline decoration-[#00f0ff] decoration-2 underline-offset-4 transition-colors group-hover:decoration-[#00f0ffcc]">
                  Subscribe&nbsp;to&nbsp;Max&nbsp;today&nbsp;with&nbsp;Xumo
                </span>
              </span>

              <div className="weight-300 mt-2 justify-start text-left text-xs text-[#fff7]">
                Valid on the With Ads plan only. Offer ends 12/2/24. See max.com for terms.
              </div>
            </motion.div>
          </motion.div>
          {/*container*/}

          {/* container */}
          {/* <motion.div 
          initial={{
            x: 0,
            opacity: 1
          }}
          animate={{
            x: slide === 1 ? 0 : -10,
            opacity: slide === 1 ? 1 : 0,
            zIndex: slide === 1 ? 5 : 0
          }}
          className="absolute flex top-0 left-0 w-full h-full">
          <div className="p-6 lg:p-8 xl:p-16 xl:pr-8 flex text-white">
            <div className="relative h-full min-h-[60px] text-lg xl:text-xl weight-600 gap-2 flex">
              <div className="text-center flex flex-col items-center">
                <div className="flex grow gap-[2px] justify-center items-center">
                  <Digit value={days[0]} />
                  <Digit value={days[1]} />
                </div>
                <div className="flex shrink text-[#fff6] text-xs weight-300">D</div>
              </div>
              <div className="text-center flex flex-col items-center">
                <div className="flex grow gap-[2px] justify-center items-center">
                  <Digit value={hours[0]} />
                  <Digit value={hours[1]} />
                </div>
                <div className="flex shrink text-[#fff6] text-xs weight-300">H</div>
              </div>
              <div className="text-center flex flex-col items-center">
                <div className="flex grow gap-[2px] justify-center items-center">
                  <Digit value={minutes[0]} />
                  <Digit value={minutes[1]} />
                </div>
                <div className="flex shrink text-[#fff6] text-xs weight-300">M</div>
              </div>
              <div className="text-center flex flex-col items-center">
                <div className="flex grow gap-[2px] justify-center items-center">
                  <Digit value={seconds[0]} />
                  <Digit value={seconds[1]} />
                </div>
                <div className="flex shrink text-[#fff6] text-xs weight-300">S</div>
              </div>
            </div>
          </div>
          <motion.div 
            initial={{opacity: 0}}
            animate={{opacity: inView ? 1 : 0}}
            transition={{delay: 0.2}}
            className="pr-4 py-4 flex grow min-h-full items-center text-white type-body">
            <span>Don’t miss these Black Friday offers! <span className="underline decoration-2 decoration-[#fff6] underline-offset-4 group-hover:decoration-[#fffc] transition-colors">Shop&nbsp;deals</span></span>
          </motion.div>
          <div className="flex md:min-w-[40%] min-h-full relative">
            <div className="aspect-[2] w-full absolute bottom-0 right-0 overflow-clip rounded-3xl pointer-events-none">
              <div className="group-hover:scale-[1.02] transition-all relative w-full h-full">
                <MotionImage
                  style={{y:scrollY}}
                  initial={{opacity: 0}}
                  animate={{opacity: inView ? 1 : 0}}
                  transition={{delay: 0.4}}
                  src="/static/images/refresh/black-friday-devices.png"
                  priority
                  fill
                  sizes="100vw"
                  className="object-fit" />
              </div>
            </div>
          </div>
        </motion.div> */}
          {/*container*/}
        </Link>
      </div>
    </motion.section>
  );
};

export const Banner3 = () => {
  const { ref, progress: amt } = useInViewScroll([0, 1], ['start 100vh', 'end 0vh']);
  const scrollY = useTransform(amt, [0, 1], [0, 50]);

  const [slide, setSlide] = useState(0);
  const inView = useInView(ref, { once: true, margin: '-30% 0% -30% 0%' });

  const backgrounds = ['linear-gradient(to bottom, #1131f5, #000062)', 'linear-gradient(to top, rgb(20, 13, 19), rgb(91, 46, 79))'];

  const pathname = useRouter().asPath;

  const isTv = pathname.indexOf('products/xumo-tv') >= 0;

  const ctaUrl = isTv ? 'https://xumo.com' : 'https://www.xumo.com';

  const product = isTv ? 'Xumo TV' : 'Xumo Stream Box';

  return (
    <motion.section
      initial={{ opacity: 0, y: 40 }}
      animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 40 }}
      transition={{ ease: [0, 1, 1, 1] }}
      ref={ref}
      className="relative z-40 mt-16 w-full bg-white"
      data-testid={'text-section'}
    >
      <div className="wrapper my-2">
        <Link
          href={ctaUrl}
          aria-labelledby="banner-cta-label"
          className={`group relative block cursor-pointer overflow-clip outline outline-2 outline-offset-2 outline-transparent transition-colors hover:outline-[#00f0ff]`}
          style={{ background: backgrounds[slide] }}
        >
          {/* container */}
          <div className="z-5 weight-600 relative bg-[#00f0ff] p-2 text-center uppercase tracking-wider text-[#03f]">Offer ends Monday</div>
          <motion.div
            initial={{
              x: 0,
              opacity: 1,
            }}
            animate={{
              x: slide === 0 ? 0 : -10,
              opacity: slide === 0 ? 1 : 0,
              zIndex: slide === 0 ? 5 : 0,
            }}
            transition={{ ease: 'cubicInOut' }}
            className="z-5 relative flex flex-col md:flex-row"
          >
            <div className="absolute h-full w-full">
              <div className="pointer-events-none absolute right-0 h-full w-full overflow-clip">
                <MotionImage
                  initial={{ opacity: 0 }}
                  animate={{ opacity: inView ? 1 : 0 }}
                  transition={{ delay: 0.4 }}
                  src="/static/images/refresh/max-bg.jpg"
                  priority
                  fill
                  sizes="100vw"
                  className="object-cover object-right"
                />
              </div>
            </div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: inView ? 1 : 0 }}
              transition={{ delay: 0.2 }}
              className="content-wrapper-x z-5 type-body relative flex min-h-full min-w-[0px] grow flex-col items-start justify-center py-4 text-white"
            >
              <div className="flex w-full grow">
                <div className="flex flex-col">
                  <span className="text-md">&nbsp;</span>
                  <span className="weight-300 text-4xl uppercase leading-none">
                    <span className="weight-600">Black Friday</span> Deal
                  </span>
                </div>
                <div className="ml-8 flex grow flex-col">
                  <span className="text-md text-[#7178d4] line-through decoration-[#00f0ff] decoration-2">$9.99/mo</span>
                  <span id="banner-cta-label" className="weight-600 flex flex-row text-4xl leading-none text-[#00f0ff]">
                    <span className="weight-300 scale-90">$</span>2.99{' '}
                    <span className="weight-400 flex items-center pl-2 text-sm uppercase leading-tight text-white">
                      for
                      <br />6 months
                    </span>
                  </span>
                </div>
              </div>

              <div className="weight-300 mt-2 justify-start text-left text-xs text-[#fff9]">
                Valid on the With Ads plan only. Offer ends 12/2/24. See max.com for terms.
              </div>
            </motion.div>
            <div className="content-wrapper-x z-5 relative flex grow flex-col items-center justify-center py-4 text-white ">
              <div className="relative aspect-[366/100] h-[38px]">
                <MotionImage
                  src="/static/images/refresh/Max_logo.png"
                  priority
                  fill
                  sizes="100vw"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: inView ? 1 : 0 }}
                  transition={{ delay: 0 }}
                  className="object-fit"
                />
              </div>
              <div className="border-1 weight-500 mt-2 rounded-full border border-[#00f0ff] bg-[#00f0ff] px-3 py-1 text-sm uppercase text-[#03f] transition-colors group-hover:bg-transparent group-hover:text-[#00f0ff]">
                Sign up now
              </div>
            </div>
          </motion.div>
          {/*container*/}

          {/* container */}
          {/* <motion.div 
          initial={{
            x: 0,
            opacity: 1
          }}
          animate={{
            x: slide === 1 ? 0 : -10,
            opacity: slide === 1 ? 1 : 0,
            zIndex: slide === 1 ? 5 : 0
          }}
          className="absolute flex top-0 left-0 w-full h-full">
          <div className="p-6 lg:p-8 xl:p-16 xl:pr-8 flex text-white">
            <div className="relative h-full min-h-[60px] text-lg xl:text-xl weight-600 gap-2 flex">
              <div className="text-center flex flex-col items-center">
                <div className="flex grow gap-[2px] justify-center items-center">
                  <Digit value={days[0]} />
                  <Digit value={days[1]} />
                </div>
                <div className="flex shrink text-[#fff6] text-xs weight-300">D</div>
              </div>
              <div className="text-center flex flex-col items-center">
                <div className="flex grow gap-[2px] justify-center items-center">
                  <Digit value={hours[0]} />
                  <Digit value={hours[1]} />
                </div>
                <div className="flex shrink text-[#fff6] text-xs weight-300">H</div>
              </div>
              <div className="text-center flex flex-col items-center">
                <div className="flex grow gap-[2px] justify-center items-center">
                  <Digit value={minutes[0]} />
                  <Digit value={minutes[1]} />
                </div>
                <div className="flex shrink text-[#fff6] text-xs weight-300">M</div>
              </div>
              <div className="text-center flex flex-col items-center">
                <div className="flex grow gap-[2px] justify-center items-center">
                  <Digit value={seconds[0]} />
                  <Digit value={seconds[1]} />
                </div>
                <div className="flex shrink text-[#fff6] text-xs weight-300">S</div>
              </div>
            </div>
          </div>
          <motion.div 
            initial={{opacity: 0}}
            animate={{opacity: inView ? 1 : 0}}
            transition={{delay: 0.2}}
            className="pr-4 py-4 flex grow min-h-full items-center text-white type-body">
            <span>Don’t miss these Black Friday offers! <span className="underline decoration-2 decoration-[#fff6] underline-offset-4 group-hover:decoration-[#fffc] transition-colors">Shop&nbsp;deals</span></span>
          </motion.div>
          <div className="flex md:min-w-[40%] min-h-full relative">
            <div className="aspect-[2] w-full absolute bottom-0 right-0 overflow-clip pointer-events-none">
              <div className="group-hover:scale-[1.02] transition-all relative w-full h-full">
                <MotionImage
                  style={{y:scrollY}}
                  initial={{opacity: 0}}
                  animate={{opacity: inView ? 1 : 0}}
                  transition={{delay: 0.4}}
                  src="/static/images/refresh/black-friday-devices.png"
                  priority
                  fill
                  sizes="100vw"
                  className="object-fit" />
              </div>
            </div>
          </div>
        </motion.div> */}
          {/*container*/}
        </Link>
      </div>
    </motion.section>
  );
};

export const Banner4 = () => {
  const { ref, progress: amt } = useInViewScroll([0, 1], ['start 100vh', 'end 0vh']);
  const scrollY = useTransform(amt, [0, 1], [0, 50]);

  const [slide, setSlide] = useState(0);
  const inView = useInView(ref, { once: true, margin: '-30% 0% -30% 0%' });

  const backgrounds = ['linear-gradient(to bottom, #1131f5, #000062)', 'linear-gradient(to top, rgb(20, 13, 19), rgb(91, 46, 79))'];

  const pathname = useRouter().asPath;

  const isTv = pathname.indexOf('products/xumo-tv') >= 0;

  const ctaUrl = isTv ? 'https://xumo.com' : 'https://www.xumo.com';

  const product = isTv ? 'Xumo TV' : 'Xumo Stream Box';

  return (
    <motion.section
      initial={{ opacity: 0, y: 40 }}
      animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 40 }}
      transition={{ ease: [0, 1, 1, 1] }}
      ref={ref}
      className="relative z-40 mt-16 w-full bg-white"
      data-testid={'text-section'}
    >
      <div className="wrapper my-2">
        <Link
          href={ctaUrl}
          aria-labelledby="banner-cta-label"
          className={`group relative block cursor-pointer overflow-clip rounded-3xl outline outline-2 outline-offset-2 outline-transparent transition-colors hover:outline-[#00f0ff]`}
        >
          {/* container */}
          <div className="z-5 weight-600 relative bg-[#00f0ff] p-2 text-center uppercase tracking-wider text-[#03f]">Offer ends Monday</div>
          <motion.div
            initial={{
              x: 0,
              opacity: 1,
            }}
            animate={{
              x: slide === 0 ? 0 : -10,
              opacity: slide === 0 ? 1 : 0,
              zIndex: slide === 0 ? 5 : 0,
            }}
            transition={{ ease: 'cubicInOut' }}
            style={{ background: 'linear-gradient(to right, #1131f5 27%, #000062 100%)' }}
            className="z-5 relative flex flex-col md:flex-row"
          >
            <div className="absolute h-full w-full">
              <div className="pointer-events-none absolute right-0 h-full w-full overflow-clip">
                <MotionImage
                  initial={{ opacity: 0 }}
                  animate={{ opacity: inView ? 1 : 0 }}
                  transition={{ delay: 0.4 }}
                  src="/static/images/refresh/max-bg-alt.jpg"
                  priority
                  fill
                  sizes="100vw"
                  className="object-cover object-right"
                />
              </div>
            </div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: inView ? 1 : 0 }}
              transition={{ delay: 0.2 }}
              className="content-wrapper-x z-5 type-body relative flex min-h-full min-w-[0px] grow flex-col items-start justify-center py-4 text-white"
            >
              <div className="flex w-full grow">
                <div className="flex flex-col">
                  <span className="text-md">&nbsp;</span>
                  <span className="weight-300 text-4xl uppercase leading-none">
                    <span className="weight-600">Black Friday</span> Deal
                  </span>
                </div>
                <div className="ml-8 flex grow flex-col">
                  <span className="text-md text-[#7178d4] line-through decoration-[#00f0ff] decoration-2">$9.99/mo</span>
                  <span id="banner-cta-label" className="weight-600 flex flex-row text-4xl leading-none text-[#00f0ff]">
                    <span className="weight-300 scale-90">$</span>2.99{' '}
                    <span className="weight-400 flex items-center pl-2 text-sm uppercase leading-tight text-white">
                      for
                      <br />6 months
                    </span>
                  </span>
                </div>
                <div className="ml-4 flex grow flex-col">
                  <span className="text-md">&nbsp;</span>
                  <div>
                    <div className="border-1 weight-500 inline-block rounded-full border border-[#00f0ff] bg-[#00f0ff] px-3 py-[0.3rem] text-sm uppercase text-[#03f] transition-colors group-hover:bg-transparent group-hover:text-[#00f0ff]">
                      Sign up now
                    </div>
                  </div>
                </div>
              </div>

              <div className="weight-300 mt-2 justify-start text-left text-xs text-[#fff9]">
                Valid on the With Ads plan only. Offer ends 12/2/24. See max.com for terms.
              </div>
            </motion.div>
            <div className="content-wrapper-x z-5 relative flex grow flex-col items-end justify-center py-4 text-white ">
              <div className="relative aspect-[366/100] h-[38px]">
                <MotionImage
                  src="/static/images/refresh/Max_logo.png"
                  priority
                  fill
                  sizes="100vw"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: inView ? 1 : 0 }}
                  transition={{ delay: 0 }}
                  className="object-fit"
                />
              </div>
            </div>
          </motion.div>
          {/*container*/}

          {/* container */}
          {/* <motion.div 
          initial={{
            x: 0,
            opacity: 1
          }}
          animate={{
            x: slide === 1 ? 0 : -10,
            opacity: slide === 1 ? 1 : 0,
            zIndex: slide === 1 ? 5 : 0
          }}
          className="absolute flex top-0 left-0 w-full h-full">
          <div className="p-6 lg:p-8 xl:p-16 xl:pr-8 flex text-white">
            <div className="relative h-full min-h-[60px] text-lg xl:text-xl weight-600 gap-2 flex">
              <div className="text-center flex flex-col items-center">
                <div className="flex grow gap-[2px] justify-center items-center">
                  <Digit value={days[0]} />
                  <Digit value={days[1]} />
                </div>
                <div className="flex shrink text-[#fff6] text-xs weight-300">D</div>
              </div>
              <div className="text-center flex flex-col items-center">
                <div className="flex grow gap-[2px] justify-center items-center">
                  <Digit value={hours[0]} />
                  <Digit value={hours[1]} />
                </div>
                <div className="flex shrink text-[#fff6] text-xs weight-300">H</div>
              </div>
              <div className="text-center flex flex-col items-center">
                <div className="flex grow gap-[2px] justify-center items-center">
                  <Digit value={minutes[0]} />
                  <Digit value={minutes[1]} />
                </div>
                <div className="flex shrink text-[#fff6] text-xs weight-300">M</div>
              </div>
              <div className="text-center flex flex-col items-center">
                <div className="flex grow gap-[2px] justify-center items-center">
                  <Digit value={seconds[0]} />
                  <Digit value={seconds[1]} />
                </div>
                <div className="flex shrink text-[#fff6] text-xs weight-300">S</div>
              </div>
            </div>
          </div>
          <motion.div 
            initial={{opacity: 0}}
            animate={{opacity: inView ? 1 : 0}}
            transition={{delay: 0.2}}
            className="pr-4 py-4 flex grow min-h-full items-center text-white type-body">
            <span>Don’t miss these Black Friday offers! <span className="underline decoration-2 decoration-[#fff6] underline-offset-4 group-hover:decoration-[#fffc] transition-colors">Shop&nbsp;deals</span></span>
          </motion.div>
          <div className="flex md:min-w-[40%] min-h-full relative">
            <div className="aspect-[2] w-full absolute bottom-0 right-0 overflow-clip pointer-events-none">
              <div className="group-hover:scale-[1.02] transition-all relative w-full h-full">
                <MotionImage
                  style={{y:scrollY}}
                  initial={{opacity: 0}}
                  animate={{opacity: inView ? 1 : 0}}
                  transition={{delay: 0.4}}
                  src="/static/images/refresh/black-friday-devices.png"
                  priority
                  fill
                  sizes="100vw"
                  className="object-fit" />
              </div>
            </div>
          </div>
        </motion.div> */}
          {/*container*/}
        </Link>
      </div>
    </motion.section>
  );
};
