import { motion } from 'framer-motion';

type HamburgerButtonProps = {
  isNavOpen: boolean;
  setIsNavOpen: (isNavOpen: boolean) => void;
  color: string;
};

export const HamburgerButton = ({ color, isNavOpen, setIsNavOpen }: HamburgerButtonProps) => {
  return (
    <motion.div
      className={`relative z-20 ml-6 h-[24px] w-[24px] space-y-2 hover:cursor-pointer lg:hidden`}
      animate={{ top: isNavOpen ? '0.4rem' : '0.25rem' }}
      transition={{ deltaRest: 0.0001 }}
      onClick={() => setIsNavOpen(!isNavOpen)}
    >
      <span
        className={`${isNavOpen ? 'top-[12.5%] rotate-45' : 'top-0'} absolute block h-[2px] w-[24px] transition-all duration-200 will-change-auto`}
        style={{ transformOrigin: 'center', background: '#000' }}
      />
      <span style={{ background: '#000' }} className={`${isNavOpen ? 'hidden' : 'absolute top-0 block h-[2px] w-[24px] transition-all will-change-auto'}`} />
      <span
        className={`${
          isNavOpen ? 'top-[calc(-12.5%-2px)] rotate-[-45deg]' : 'top-[8px]'
        } absolute  block h-[2px] w-[24px] transition-all duration-200 will-change-auto`}
        style={{ transformOrigin: 'center', background: '#000' }}
      />
    </motion.div>
  );
};
