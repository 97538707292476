'use client';

/* eslint-disable max-len */
import { motion, useSpring, useTransform, cubicBezier } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { useInViewScroll, useSanitizedTranslation, useWindowSize } from '../hooks';
import { makeVariants } from '../utils/makeVariants';
import { MotionImage } from './AnimatedImage';
import { CopyProps, CopySection } from './CopySection';
import { useScrolledTheme, GlobeBlock, StatsBlock, TechStackBlock } from 'ui';
import { useRouter } from 'next/router';
import Link from 'next/link';
import Image from 'next/image';

import { CTA, Colors, IImage } from '../types';

export type AdvertisingStatsProps = {
  copyProps: CopyProps;
  backgroundColor: string;
  imageLayout: { top: Layout; bottom: Layout };
  topSwirl: IImage;
  bottomSwirl?: IImage;
  deviceImages?: IImage[];
  devicePlacement?: 'left-overlap' | 'left' | 'right-overlap' | 'right';
  ctas: CTA[];
  headingOne?: boolean;
  fineprint?: string;
  imageFineprint?: string;
};

type Layout = 'full-screen' | 'right' | 'right-wide';

const squiggleVariant = {
  hidden: {
    opacity: 0,
    y: 30,
    transition: {
      when: 'afterChildren',
      delayChildren: 0,
      staggerChildren: 0.025,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      staggerChildren: 0.055,
      type: 'spring',
      stiffness: 90,
      damping: 23,
    },
  },
};

// TODO: Add accents to TvSplash
const colorFallback = {
  '/shop': '#396059',
  '/shop/xumo-tv': '#0029FF',
  '/shop/xumo-stream-box': '#0029FF',
  '/shop/streaming': '#0029FF',
};

const retailFg = '/static/images/blue-ribbon.png';
const retailSwirl = '/static/images/blue-ribbon.png';

const streamingFg = '/static/images/xumo-stream-box-product.png';
const streamingSwirl = '/static/images/blue-ribbon.png';

// TODO: add to Directus
const video = 'https://xumo-marketing.directus.app/assets/8630252f-4f56-4c2f-b922-342de8c54a91';

const DescriptiveButton: React.FC<{ heading: string; description: string; href: string }> = ({ heading, description, href }) => {
  const { t } = useSanitizedTranslation();
  const chevronVariant = {
    initial: { x: 0, transition: { type: 'spring', damping: 65, stiffness: 900, mass: 1 } },
    animate: { x: 10, transition: { type: 'spring', damping: 65, stiffness: 900, mass: 1 } },
  };

  const buttonVariant = {
    initial: { background: '#d8e7f300', transition: { type: 'spring', damping: 65, stiffness: 900, mass: 1 } },
    animate: { background: '#d8e7f3ff', transition: { type: 'spring', damping: 65, stiffness: 900, mass: 1 } },
  };

  return (
    <motion.div variants={buttonVariant} initial="initial" whileHover="animate" className="-ml-3 mb-1 cursor-pointer overflow-hidden rounded-lg p-3 text-black">
      <Link href={href} passHref className="flex flex-col">
        <span className="type-subheading text-black">
          <span data-i18n>{t(heading)}</span>
          <motion.svg
            variants={chevronVariant}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 622.6 657.02"
            className="ml-2 inline-block object-contain"
            style={{ width: 20, height: 20 }}
          >
            <path fill={'#06B2B6'} d="M171.96,0l246.9,328.36-247.44,328.66h193.37l247.21-328.36L365.25,.31l-.23-.31H171.96Z" />
          </motion.svg>
        </span>
        <span data-i18n className="type-body text-base text-xumoOnyx">
          {t(description)}
        </span>
      </Link>
    </motion.div>
  );
};

export const AdvertisingStats: React.FC<AdvertisingStatsProps> = ({
  copyProps,
  backgroundColor,
  topSwirl,
  bottomSwirl,
  deviceImages: imgs,
  devicePlacement,
  imageLayout,
  headingOne,
  fineprint,
  imageFineprint,
  ctas,
}) => {
  const { width } = useWindowSize();
  const { ref, progress: y } = useInViewScroll([0, 100], ['start 100vh', 'end 0vh']);
  const amt = useSpring(y, { stiffness: 400, damping: 90 });
  const containerY = useTransform(amt, [0, 1], ['-15vh', '0']);

  const router = useRouter();

  const variants = makeVariants.slideIn();
  const imgVariants = {
    initial: {
      opacity: 0,
      rotateY: '-50deg',
      x: 30, //devicePlacement === 'right-overlap' ? 30 : devicePlacement === 'left-overlap' ? -30 : 0,
    },
    animate: (i: number) => ({
      opacity: 1,
      rotateY: '-10deg',
      x: 0,
      transition: {
        type: 'spring',
        delay: 0.4 * i,
      },
    }),
  };

  const videoRef = useRef();

  const [videoShown, showVideo] = useState(false);

  useEffect(() => {
    if (videoShown && videoRef.current) {
      (videoRef.current as any).play();
    }
  }, [videoShown]);

  const tvHero = router.asPath.indexOf('/products/xumo-tv') === 0 && headingOne;
  const enterprise = router.asPath.indexOf('/enterprise') === 0 && router.asPath.indexOf('/enterprise/case') !== 0;
  const enterpriseHero = enterprise && headingOne;

  const isAdvertising = router.asPath.indexOf('/advertising') === 0;
  const adStyles = isAdvertising ? { style: { background: 'white', borderRadius: 15 } } : {};

  // right: A8306C

  return (
    <>
      <section ref={ref} className={`wrapper relative z-10 flex w-full flex-col pb-[50px] max-md:mt-[50px] md:flex-row`} data-id={'tv-splash-block'}>
        <motion.div
          variants={variants}
          initial="initial"
          whileInView={'animate'}
          viewport={{ once: true }}
          className={`relative space-y-4`}
          style={{ color: '#000' }}
        >
          <div className="relative w-full overflow-hidden rounded-xl bg-xumoBerry">
            <img src="/static/images/gradient-1.svg" className="absolute bottom-0 left-0 right-0 top-0 z-0 h-full w-full object-cover" alt="" />
            <div className="relative z-10 flex flex-col p-8 lg:flex-row">
              <div className="flex flex-col items-center justify-center p-4 text-center lg:border-r lg:border-white lg:pr-16">
                <span data-i18n className="weight-900 flex font-WorkSans text-7xl leading-[90%] text-white" style={{ filter: 'drop-shadow(5px 5px 0px #BB2E76)' }}>
                  Over 10,000
                </span>
                <span data-i18n className="type-body flex pt-4 text-white">ad supported titles</span>
              </div>
              <div className="flex flex-col p-4 text-left lg:pl-16">
                <span data-i18n className="type-heading mb-4 flex text-3xl text-white">Investing in more content fans love</span>
                <span data-i18n className="type-body-base flex text-white">
                  We are taking center stage by continuously adding new content and channels on Xumo from content partners like NBCUniversal, BBC, and Magnolia.
                  We continue to invest in more end-points to give our advertisers more reach, like the first-ever pay TV integration with Xfinity. Our audience
                  enjoys 10,000+ ad support titles, giving our advertisers maximum impact to reach potential customers on CTV, AVOD, and FAST.
                </span>
              </div>
            </div>
          </div>

          <div className="">
            <div className="relative h-[400px] overflow-hidden rounded-xl">
              <MotionImage
                className={`w-full object-cover max-sm:h-full`}
                sizes="(max-width: 768px) 100vw, (max-width: 1600px) 60vw, 50vw"
                quality={80}
                src={'/static/images/ad-stats.png'}
                alt={''}
                priority
                fill
              />
            </div>
          </div>

          <div className="relative z-10 flex flex-col gap-4 lg:flex-row">
            <div className="relative flex basis-1/2 flex-col items-center overflow-hidden rounded-xl p-8">
              <img src="/static/images/gradient-1.svg" className="absolute bottom-0 left-0 right-0 top-0 z-0 h-full w-full object-cover" alt="" />
              <div className="relative z-10 mb-4">
                <span data-i18n className="type-heading mb-4 flex text-center text-3xl text-white">
                  Xumo delivers an
                  <br />
                  incremental audience
                </span>
              </div>
              <div className="relative z-10 flex">
                <span className="weight-900 flex pr-4 font-WorkSans text-5xl leading-[90%] text-white" style={{ filter: 'drop-shadow(5px 5px 0px #BB2E76)' }}>
                  95%
                </span>
                <span data-i18n className="text-left text-white">of HHs reached by Xumo were incremental to linear TV portion of campaigns*</span>
              </div>
              <div className="relative z-10 mb-4 mt-2 flex w-full text-left">
                <span className="inline-block h-[30px] w-full overflow-hidden bg-white bg-clip-padding">
                  <motion.span
                    initial={{ scaleX: 0 }}
                    whileInView={{ scaleX: 1 }}
                    transition={{ delay: 0.05, duration: 1.5, deltaRest: 0.00001, deltaSpeed: 0.00001, ease: cubicBezier(0.35, 0, 0.44, 1) }}
                    viewport={{ once: true }}
                    className="inline-block h-full w-[95%] origin-left bg-[#BB2E76]"
                  />
                </span>
              </div>

              <div className="relative z-10 flex">
                <span className="weight-900 flex pr-4 font-WorkSans text-5xl leading-[90%] text-white" style={{ filter: 'drop-shadow(5px 5px 0px #BB2E76)' }}>
                  87%
                </span>
                <span data-i18n className="text-left text-white">of HHs reached by Xumo cannot be reached through other FAST services*</span>
              </div>
              <div className="relative z-10 mt-2 flex w-full text-left">
                <span className="inline-block h-[30px] w-full overflow-hidden bg-white bg-clip-padding">
                  <motion.span
                    initial={{ scaleX: 0 }}
                    whileInView={{ scaleX: 1 }}
                    viewport={{ once: true }}
                    transition={{ delay: 0.15, duration: 1.5, deltaRest: 0.00001, deltaSpeed: 0.00001, ease: cubicBezier(0.35, 0, 0.44, 1) }}
                    className="inline-block h-full w-[87%] origin-left bg-[#BB2E76]"
                  />
                </span>
              </div>
            </div>
            <div className="relative flex basis-1/2 flex-col justify-between overflow-hidden rounded-xl">
              <img src="/static/images/gradient-1.svg" className="absolute bottom-0 left-0 right-0 top-0 z-0 h-full w-full -scale-x-100 object-cover" alt="" />
              <div className="relative z-10 flex grow flex-col items-center justify-center p-8 text-center">
                <span className="weight-900 flex font-WorkSans text-9xl leading-[90%] text-white" style={{ filter: 'drop-shadow(5px 5px 0px #BB2E76)' }}>
                  95%
                </span>
                <span data-i18n className="type-body flex pt-4 text-white">Addressable backed by MVPD data enablement can yield a 95% initial match rate*​</span>
              </div>
              <div data-i18n className="type-body relative z-10 p-4 text-[10px] text-white">
                *Source: Analysis based on Comcast Advertising data. Study source -CIMM/GoAddressable  Guidelines for Planning & Buying Addressable TV
                Advertising’ February 2024. WEB. 28 Feb 2024.​
              </div>
            </div>
          </div>
        </motion.div>
      </section>
    </>
  );
};
