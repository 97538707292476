import { SUPPORTED_LOCALES } from '../utils/locales';

import websiteContent from 'config/xumo-website-output.json';
import devices from 'ui/devices.json';
import { DirectusPage } from 'config';
import { Website } from '../types';
import _ from 'lodash';

export interface GetGeneralPageStaticPathsArgs {
  website: Website.Corporate | Website.Enterprise | Website.Shopper;
}

export interface GetGeneralPageStaticPathsItem {
  params: {
    slug: string[];
  };
  locale: string;
}

export const getGeneralPageStaticPaths =
  ({ website }: GetGeneralPageStaticPathsArgs) =>
  async (): Promise<{ paths: GetGeneralPageStaticPathsItem[]; fallback: boolean }> => {
    const data = (websiteContent as any).content as DirectusPage[];
    const pressData = (websiteContent as any).press;

    // Converts the slug into an array of strings, removing any empty values
    let pages: string[][] = data
      .map(({ slug }: { slug?: string }) => (slug ? slug.split('/') : null))
      .filter((slug): slug is string[] => !!slug && !(slug[0] === '404'));

    let press: string[][] = pressData.map(({ slug }: { slug?: string }) => (slug as string).split('/'));

    let routes: string[][] = [...pages];

    const paths = [];

    const allLocalePaths = SUPPORTED_LOCALES;

    for (let j = 0; j < devices.length; j++) {
      const model = devices[j];

      for (let k = 0; k < model.sizes.length; k++) {
        const size = model.sizes[k];
        const slug = model.heading
          .replace('$size', size)
          .split(' ')
          .map(str => str.toLowerCase())
          .join('-');

        const route = ['products', 'xumo-tv', slug];

        routes.push(route);
      }
    }

    for (let j = 0; j < routes.length; j++) {
      const route = routes[j];
      const isAdvertising = route.indexOf('advertising') >= 0;

      for (let i = 0; i < SUPPORTED_LOCALES.length; i++) {
        const locale = allLocalePaths[i];

        // do not localize this section yet
        if (locale !== 'en' && isAdvertising) {
          continue;
        }

        paths.push({
          params: {
            slug: route,
          },
          locale: locale || 'en',
        });
      }
    }

    for (let j = 0; j < press.length; j++) {
      const route = press[j];

      paths.push({
        params: {
          slug: route,
          press: true,
        },
        locale: 'en',
      });
    }

    /* for (let j = 0; j < devices.length; j++) {
      const model = devices[j];

      for (let k = 0; k < model.sizes.length; k++) {
        const size = model.sizes[k];
        const slug = model.heading
          .replace('$size', size)
          .split(' ')
          .map(str => str.toLowerCase())
          .join('-');

        const route = ['products', 'xumo-tv', slug];

        paths.push({
          params: {
            slug: route,
            deviceId: j,
            productId: model.productId,
          },
          locale: 'en',
        });
      }
    } */

    return {
      paths,
      fallback: false,
    };
  };
