/v* eslint-disable max-len */;
import React, { useRef } from 'react';
import { CTA, IImage } from '../types/common';
import { useRouter } from 'next/router';
import { AnimatePresence, motion, useSpring, useTransform } from 'framer-motion';
import {
  CTAList,
  Bullet,
  Button,
  IBullet,
  SplitH1,
  SplitH2,
  SplitH3,
  useInViewScroll,
  useWindowSize,
  useScrolledTheme,
  useSanitizedTranslation,
  MotionImage,
} from 'ui';
import { makeVariants } from '../utils/makeVariants';

export interface IPosition {
  x?: number;
  y?: number;
}

export interface SwirlBlockProps {
  heading?: string;
  subTitle?: string;
  body?: string;
  bullets?: IBullet[];
  primary: string;
  secondary?: string;
  backgroundColor: string;
  coloredText: boolean;
  style: 'left' | 'right' | 'center';
  initialPosition: IPosition;
  bgImage?: IImage;
  cta?: CTA[];
  bgPosition?: 'top' | 'bottom';
  bgOffset?: IPosition;
  bgScale?: number;
  headingOne?: boolean;
  copyBlocks?: any[];
}

const squiggleVariant = {
  hidden: {
    opacity: 0,
    transition: {
      when: 'afterChildren',
      delayChildren: 0,
      staggerChildren: 0.025,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.055,
      type: 'spring',
      stiffness: 90,
      damping: 23,
      restDelta: 0.001,
    },
  },
};

export const SwirlBlock: React.FC<SwirlBlockProps> = ({
  heading,
  body,
  bullets,
  backgroundColor,
  coloredText,
  style,
  primary,
  secondary,
  bgImage,
  subTitle,
  bgPosition,
  bgOffset,
  bgScale,
  initialPosition = { x: -20, y: 0 },
  cta,
  headingOne,
  copyBlocks,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const { t } = useSanitizedTranslation();

  const { ref, progress } = useInViewScroll([0, 1], ['start 50vh', 'end 50vh']);
  const movement = useSpring(progress, { stiffness: 400, damping: 90 });
  const y = useTransform(progress, [0, 1], ['0', '-15vh']);
  const opacity = useTransform(progress, [0, 0.4, 0.6, 1], [0.05, 1, 1, 0.05]);

  const router = useRouter();

  const variants = makeVariants.slideIn();

  const HeadingComponent = headingOne ? SplitH1 : SplitH2;
  const SubHeading = headingOne ? SplitH2 : SplitH3;

  const { width } = useWindowSize();
  const isMobile = width <= 719;

  const sectionRef = useRef();
  const { componentInView } = useScrolledTheme({
    ref,
    background: backgroundColor,
    accent: primary,
    text: '#000000',
  });

  return (
    <>
      <div
        className="pointer-events-none fixed bottom-0 left-0 right-0 top-0 z-0 h-[120vh] overflow-hidden"
        style={{ transform: style === 'right' ? 'scale(-1)' : 'scale(1)' }}
      >
        <motion.div style={{ y, opacity }} className="container relative mx-auto h-full px-10 max-lg:opacity-10">
          <AnimatePresence>
            {componentInView && (
              <MotionImage
                key="squiggle"
                initial="hidden"
                animate="show"
                exit="hidden"
                fill
                variants={squiggleVariant}
                src={bgImage?.src}
                className="relative left-[-10%] h-full  w-full object-cover object-left md:left-[50%] lg:w-auto"
              />
            )}
          </AnimatePresence>
        </motion.div>
      </div>
      <motion.section className="w-full overflow-hidden sm:pb-0 sm:pt-0" data-testid={'swirl-section'}>
        <motion.div
          ref={ref}
          initial="initial"
          whileInView="animate"
          viewport={{ once: true, margin: '-50% 0% -50% 0%' }}
          variants={variants}
          className={`py-16 lg:py-24 ${headingOne ? 'h-[75vh]' : 'min-h-[400px]'} relative flex w-full justify-center ${
            style === 'center' ? 'text-center' : 'text-left'
          }`}
        >
          <div className={`wrapper relative  mx-auto flex flex-row ${style === 'center' ? 'justify-center' : 'justify-start'}`}>
            <div className={`relative  z-20 flex flex-col justify-center pr-1 lg:w-1/2 lg:pr-16`}>
              {heading && (
                <HeadingComponent
                  variants={variants}
                  content={heading}
                  className={`mb-6 ${headingOne ? 'type-title' : 'type-heading'}`}
                  style={{ color: coloredText ? primary : 'default' }}
                />
              )}
              {subTitle && <SubHeading content={subTitle} className={`type-subheading mb-6`} style={{ color: secondary || primary || 'default' }} />}
              {body && (
                <motion.div
                  variants={variants}
                  className={`allow-html type-body mb-6`}
                  dangerouslySetInnerHTML={{ __html: t(body) }}
                  style={{ color: coloredText ? primary : 'default' }}
                  data-i18n={body}
                />
              )}
              {bullets ? (
                <div className="flex flex-col md:flex-row md:justify-between">
                  {bullets.map((bullet, key) => (
                    <Bullet {...bullet} key={key} variants={variants} />
                  ))}
                </div>
              ) : null}

              {cta?.length && (
                <motion.div className={`flex space-x-5 ${copyBlocks ? 'pt-5' : 'py-5'}`} variants={variants}>
                  {cta.map((cta, id) => {
                    return (
                      cta?.label && (
                        <motion.div key={id + ' ' + cta.label} variants={variants}>
                          <Button
                            buttonType="primary"
                            label={cta.label}
                            href={cta.href}
                            styleOverrides={{
                              tailwind: `px-6 py-3`,
                              css: { backgroundColor: primary },
                            }}
                            data-i18n={cta.label}
                          />
                        </motion.div>
                      )
                    );
                  })}
                </motion.div>
              )}

              {copyBlocks?.map((block: any) => (
                <div className="border-t-[1px] border-solid border-xumoAegean py-8">
                  {block.heading && <SubHeading content={block.heading} className={`type-heading`} />}
                  <motion.div
                    variants={variants}
                    className={`type-body mt-5 md:max-w-full`}
                    dangerouslySetInnerHTML={{ __html: block.body ? t(block.body as string) : '' }}
                    data-i18n={block.body}
                  />
                  {!!block.ctas?.length && (
                    <div className="mt-5 flex ">
                      <CTAList ctas={block.ctas} />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </motion.div>
      </motion.section>
    </>
  );
};
