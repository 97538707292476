'use client';

/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-len */
import { motion } from 'framer-motion';
import React, { useContext } from 'react';
import { Button, CTAList, isEnterpriseOrShopper, PageContext, useInViewScroll, useSanitizedTranslation, useScrolledTheme } from 'ui';
import { CTA, IImage } from '../types/common';
import { makeVariants } from '../utils/makeVariants';
import { Chevron, SplitH1, SplitH2, SplitH3 } from './';

export interface ChevronBlockProps {
  backgroundColor: string;
  accent: string;
  chevronAccent?: string;
  heading: string;
  body: string;
  subheading?: string;
  secondaryHeading?: string;
  secondaryBody?: string;
  chevronPlacement: 'left' | 'right';
  image: IImage;
  ctas?: CTA[];
  secondaryCta?: CTA;
  secondMainButtonCta?: CTA;
  secondaryCta2?: CTA;
  headingOne?: boolean;
  copyBlocks?: any[];
  lottie?: any;
}

export const ChevronBlock: React.FC<ChevronBlockProps> = ({
  heading,
  subheading,
  body,
  accent,
  backgroundColor,
  chevronAccent,
  image = { src: '/chevron-placeholder.png', alt: '' },
  chevronPlacement,
  ctas,
  headingOne,
  copyBlocks,
  lottie,
}) => {
  const { ref, progress: amt } = useInViewScroll([0, 1], ['start 100vh', 'end 0vh']);
  const { t } = useSanitizedTranslation();
  const variants = makeVariants.slideIn();
  const { pageCtx } = useContext(PageContext);
  const host = pageCtx.host;

  const Heading = headingOne ? SplitH1 : SplitH2;
  const SubHeading = headingOne ? SplitH2 : SplitH3;

  const bodyColor = backgroundColor === '#155772' ? '#ffffff' : '#000000';

  const rightBlock = (
    <div className="relative z-20 flex w-2/5 w-full flex-col justify-center text-left">
      <Heading content={heading} variants={variants} className={`type-heading transition-colors`} style={{ color: bodyColor }} />
      {subheading && (
        <SubHeading
          content={subheading}
          variants={{ ...variants, animate: { ...variants.animate, transition: { staggerChildren: 0.075, ease: 'easeOut', duration: 0.5, delay: 0.4 } } }}
          className="type-subheading allow-html mb-5 transition-colors lg:w-3/5"
          style={{ color: '#000' }}
        />
      )}
      <motion.div
        variants={variants}
        className="type-body mb-5 pt-5 transition-colors lg:w-4/5"
        style={{ color: '#000' }}
        dangerouslySetInnerHTML={{ __html: t(body) }}
        data-i18n={body}
      />
      {/* TODO: Replace with CtaList */}
      {!!ctas?.length && (
        <>
          <motion.div className="flex space-x-5 py-5" variants={variants}>
            {ctas.map(({ label, href, backgroundColor, textColor }, index) => (
              <Button
                href={href}
                label={label}
                buttonType={'link-primary'}
                key={'chevron' + index + label}
                styleOverrides={{
                  css: { backgroundColor: backgroundColor, color: textColor },
                  tailwind: `border-0 px-6 py-3`,
                }}
                icon={!!index ? { type: 'arrow', color: isEnterpriseOrShopper(host) ? '#155772' : '#BB2E76', placement: 'right' } : undefined}
              />
            ))}
          </motion.div>
        </>
      )}
      {copyBlocks?.map((block: any) => (
        <div className="mt-6 border-t-[1px] border-solid border-black pt-5">
          {block.heading && <SubHeading content={block.heading} className={`weight-700 type-heading mb-6 text-3xl leading-tight md:max-w-[65%]`} />}
          <motion.div
            variants={variants}
            className={`weight-300 type-body mt-5 text-lg md:max-w-full`}
            dangerouslySetInnerHTML={{ __html: block.body ? t(block.body as string) : '' }}
            data-i18n={block.body}
          />
          {!!block.ctas.length && (
            <div className="mt-5 flex ">
              <CTAList ctas={block.ctas} />
            </div>
          )}
        </div>
      ))}
    </div>
  );

  const leftBlock = (
    <motion.div
      key="chevron"
      initial="initial"
      whileInView="animate"
      viewport={{ once: true, margin: '-50% 0% -50% 0%' }}
      variants={variants}
      className={`flex min-h-[300px] w-full max-lg:max-h-[300px] lg:absolute lg:h-[450px] xl:min-h-[550px] 2xl:min-h-[650px]`}
    >
      <Chevron chevronPlacement={chevronPlacement} progress={amt} bgColor={chevronAccent || accent} image={image.src} lottie={lottie} />
    </motion.div>
  );

  const squiggleVariant = {
    hidden: {
      opacity: 0,
      y: 30,
      transition: {
        when: 'afterChildren',
        delayChildren: 0,
        staggerChildren: 0.025,
        type: 'spring',
        stiffness: 400,
        damping: 40,
      },
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        staggerChildren: 0.055,
        type: 'spring',
        stiffness: 90,
        damping: 23,
      },
    },
  };

  return (
    <>
      <motion.section className={`relative z-10 w-full bg-white`} ref={ref} data-testid={'chevron-section'}>
        <div className={`wrapper flex min-h-[600px] flex-col px-10 py-16 lg:flex-row lg:py-32 ${chevronPlacement === 'right' && 'lg:flex-row-reverse'}`}>
          <motion.div className="relative flex items-center justify-center max-lg:pb-16 lg:w-3/5">
            <div className={`flex h-[275px] items-center lg:absolute lg:h-[500px] lg:w-full`}>{leftBlock}</div>
          </motion.div>
          <motion.div
            variants={variants}
            initial="initial"
            whileInView="animate"
            viewport={{ once: true, margin: headingOne ? '0px' : '-25% 0% -25% 0%' }}
            className="flex justify-center lg:w-2/5"
          >
            <div className={`flex items-center justify-center`}>{rightBlock}</div>
          </motion.div>
        </div>
      </motion.section>
    </>
  );
};
