import { HTMLMotionProps, motion } from 'framer-motion';
import Link from 'next/link';
import { useContext } from 'react';
import { ButtonIcon, PageContext, StyleOverride, ToggleButton, useContent } from '../';
import { withButtonStyles } from './hocs/withButtonStyles';

// TODO: Move variants to Enum
export type ButtonVariant = 'link' | 'primary' | 'link-primary' | 'secondary' | 'dropdown' | 'submit' | 'toggle' | 'submit-primary';
export enum ButtonHtmlElement {
  a = 'a',
  button = 'button',
  input = 'input',
  div = 'div',
}

export type ButtonProps<T extends HTMLElement> = {
  label: string;
  href?: string;
  handleClick?: (args?: any) => void;
  buttonType?: ButtonVariant;
  icon?: typeof ButtonIcon;
  styleOverrides?: StyleOverride;
  underline?: boolean;
  isDisabled?: boolean;
  isSelected?: boolean;
  isCorporate?: boolean;
  isOpen?: boolean;
  ref: any;
};

// TODO: Pass in Shape prop (circle, square, rectangle)
const LinkButton = withButtonStyles<HTMLMotionProps<ButtonHtmlElement.a>, HTMLAnchorElement>(motion.a, 'py-3 px-6');
const ActionButton = withButtonStyles<HTMLMotionProps<ButtonHtmlElement.button>, HTMLButtonElement>(motion.button);
const SubmitButton = withButtonStyles<HTMLMotionProps<ButtonHtmlElement.button>, HTMLButtonElement>(motion.button, 'py-3 px-6');

export const Button = ({ buttonType, label, href = '', handleClick, icon, styleOverrides, isSelected, isOpen, ref, ...rest }: ButtonProps<HTMLElement>) => {
  const labelContent = useContent(label, [label]);
  const { pageCtx } = useContext(PageContext);
  const { isCorporate } = pageCtx;
  const commonProps = {
    label: labelContent,
    icon,
    ...rest,
  };

  const isPrimary = (value?: string) => !!value?.includes('primary') || rest.isDisabled;
  const p = isPrimary(buttonType);

  switch (buttonType) {
    // TODO: Create a LinkButton component that handles the href and passHref props
    case 'link-primary':
    case 'link':
    case 'primary':
      return (
        <Link ref={ref} href={href} passHref legacyBehavior>
          <LinkButton
            {...commonProps}
            href={href}
            buttonType={buttonType}
            handleClick={handleClick}
            data-i18n={label}
            isCorporate={isCorporate}
            isPrimary={p}
            styleOverrides={styleOverrides}
          />
        </Link>
      );
    case 'submit-primary':
    case 'submit':
      return (
        <SubmitButton
          ref={ref}
          {...commonProps}
          handleClick={handleClick}
          data-i18n={label}
          buttonType={buttonType}
          isCorporate={isCorporate}
          isPrimary={p}
          styleOverrides={styleOverrides}
        />
      );
    case 'toggle':
      return (
        <ToggleButton
          {...commonProps}
          setIsOpen={handleClick ?? (() => {})}
          setIsClosed={handleClick ?? (() => {})}
          isOpen={isSelected ?? false}
          styleOverrides={styleOverrides}
        />
      );
    case 'toggle':
      return <ToggleButton {...commonProps} setIsOpen={handleClick ?? (() => {})} setIsClosed={handleClick ?? (() => {})} isOpen={isOpen ?? false} />;

    case 'dropdown':
    default:
      return (
        <ActionButton
          {...commonProps}
          handleClick={handleClick}
          data-i18n={label}
          buttonType={buttonType}
          isCorporate={isCorporate}
          isPrimary={p}
          styleOverrides={styleOverrides}
        />
      );
  }
};
