/* eslint-disable react/react-in-jsx-scope */
import { memo } from 'react';
import { Helmet } from 'react-helmet';

import { useAdobePageAnalytics } from '../hooks';

export type DataLayer = Record<string, string | ((args: any) => void)>;
type AdobeAnalyticsProps = {
  path: string;
  locale: string;
  title?: string;
};

const getSubcategory = segments => {
  const segment = segments[1];
  switch (segment) {
    case 'enterprise':
      return 'enterprise';
    case 'company':
    case 'press':
    case 'events':
    case 'news':
      return 'corporate';
    case '':
      return 'landing';
    default:
      return 'shopper';
  }
};

const screenNameMap = {
  '/': 'home',
  '/products/xumo-tv': 'xumo tv',
  '/company': 'about',
  '/press': 'press',
  '/enterprise': 'enterprise home',
  '/enterprise/contant': 'contact us',
  '/enterprise/cms': 'enterprise cms',
};

// TODO: pull from CMS
const getScreenName = path => {
  const pathSegments = path.split('/');
  const pathString = pathSegments.slice(1).join('/');

  const screenName = screenNameMap['/' + pathString];

  return screenName || (pathString.indexOf('press') >= 0 ? pathSegments.slice(-1)[0].split('-').join(' ') : 'page');
};

const Analytics = ({ path, locale, title }: AdobeAnalyticsProps) => {
  const pathSegments = path.split('/');

  const businessType = 'resi';
  const siteType = 'sales';
  const screenName = getScreenName(path);

  const primaryCategory = 'xumo';
  const subCategory1 = getSubcategory(pathSegments);
  const subCategory2 = '';
  const pageName = `${businessType}|${siteType}|${primaryCategory}|${subCategory1}|${screenName}`;

  const dataLayer: DataLayer = {
    category: JSON.stringify({
      category: {
        primaryCategory,
        subCategory1,
        subCategory2,
        businessType,
        siteType,
        designType: 'responsive',
      },
    }),
    affiliate: JSON.stringify({
      affiliate: {
        channel: 'web',
        name: 'comcast',
      },
    }),
    pageInfo: JSON.stringify({
      pageInfo: {
        language: locale,
        pageName,
        screenName,
      },
    }),
    codeBase: JSON.stringify({
      codebase: {
        name: 'Xumo marketing page',
        releaseVersion: '1.0.0',
      },
    }),
  };

  // Handles route changes
  useAdobePageAnalytics(title);

  return (
    <Helmet>
      <script
        key="comcast-tracking-schema"
        id={'schema-version'}
        type="tracking-data-digitalData"
        dangerouslySetInnerHTML={{ __html: `{ schemaVersion: '0.21.1' }` }}
        async
      />
      {!!Object.keys(dataLayer).length &&
        Object.keys(dataLayer).map((key: keyof DataLayer, index) => {
          if (!(typeof dataLayer[key] === 'string')) {
            return null;
          }
          return (
            <script key={'comcast-data-layer-' + index} id={`data-page-${key}`} type="tracking-data-page">
              {dataLayer[key]}
            </script>
          );
        })}
    </Helmet>
  );
};

export const AdobeAnalytics = memo(Analytics);

///export default AdobeAnalytics;
