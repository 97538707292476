import React from 'react';
import Head from 'next/head';
import { MetaProperty } from '../types';
import { getMetadatum } from '../utils';
import { useSanitizedTranslation } from '../hooks';

import { SUPPORTED_LOCALES } from '../utils/locales';

type Props = {
  title: string;
  description: string;
  url: string;
  image: string;
  cssVariables?: string;
  path: string;
};

export const PageMetadata = ({ title, description, url, path, image, cssVariables }: Props) => {
  const { t } = useSanitizedTranslation();

  // For Twitter and Facebook to display the correct image, the image must be at least 1200 x 630 pixels.
  if (description.length > 160) {
    description = description.substring(0, 157) + '...';
  }
  const imageUrl = `https://${process.env.DIRECTUS_APP_ID}.directus.app/assets/${image}`;

  return (
    <Head>
      <title>{t(title)}</title>
      <link rel="canonical" href={url} />

      <meta {...getMetadatum({ property: MetaProperty.Title, content: t(title), attributeName: 'name' })} />
      <meta {...getMetadatum({ property: MetaProperty.Description, content: t(description), attributeName: 'name' })} />
      <meta {...getMetadatum({ property: MetaProperty.Image, content: imageUrl, attributeName: 'name' })} />
      <meta {...getMetadatum({ property: MetaProperty.ViewPort, content: 'width=device-width, initial-scale=1.0', attributeName: 'name' })} />

      {/* OG Metadata */}
      <meta {...getMetadatum({ property: MetaProperty.OgTitle, content: t(title) })} />
      <meta {...getMetadatum({ property: MetaProperty.OgImage, content: imageUrl })} />
      <meta {...getMetadatum({ property: MetaProperty.OgUrl, content: url })} />
      <meta {...getMetadatum({ property: MetaProperty.OgType, content: 'website' })} />
      <meta {...getMetadatum({ property: MetaProperty.OgSiteName, content: 'Xumo' })} />
      <meta {...getMetadatum({ property: MetaProperty.OgDescription, content: t(description) })} />

      {/* <!-- Twitter Meta Tags --> */}
      <meta {...getMetadatum({ property: MetaProperty.TwitterTitle, content: t(title) })} />
      <meta {...getMetadatum({ property: MetaProperty.TwitterDescription, content: t(description) })} />
      <meta {...getMetadatum({ property: MetaProperty.TwitterImage, content: imageUrl })} />
      <meta {...getMetadatum({ property: MetaProperty.TwitterUrl, content: url })} />
      <meta {...getMetadatum({ property: MetaProperty.TwitterSite, content: '@XumoTV' })} />
      <meta {...getMetadatum({ property: MetaProperty.TwitterCard, content: 'summary_large_image' })} />
      {cssVariables ? <style>:root {`{${cssVariables}}`}</style> : null}
      <style dangerouslySetInnerHTML={{ __html: `html { scroll-behavior: smooth; }` }} />

      <link rel="alternate" hreflang={'en'} href={`https://www.xumo.com${path}`} />

      {path.indexOf('advertising') < 0 &&
        SUPPORTED_LOCALES.map(
          locale => locale !== 'en' && <link key={locale} rel="alternate" hreflang={locale} href={`https://www.xumo.com/${locale}${path}`} />,
        )}

      <link rel="alternate" hreflang={'x-default'} href={`https://www.xumo.com${path}`} />
    </Head>
  );
};

// TODO: Get Static Props for PageMetaData
// TODO: Cache image
