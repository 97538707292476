import { motion } from 'framer-motion';
import { LinkProps } from 'next/link';
import { useEffect, useContext } from 'react';
import isBrowser from '../utils/isBrowser';
import { NavigationHeaderLinkItem } from './NavigationHeaderLinkItem';
import { IImage, PageContext } from 'ui';

export type NavigationHeaderLinksProps = {
  links: HeaderLink[];
  secondaryLinks: HeaderLink[];
  isMobile?: boolean;
  styleOverrides: {
    button?: string;
    list?: string;
    links?: string;
  };
  isNavOpen: boolean;
  setIsNavOpen: (open: boolean) => void;
};

export interface HeaderLinkItem {
  label: string;
  href: string;
}
export interface HeaderLinkSection {
  title: string;
  links: HeaderLinkItem[];
}

export interface HeaderLinkFeatured {
  title: string;
  image: IImage;
  link: HeaderLinkItem;
}

export interface HeaderLink {
  href: LinkProps['href'] | string;
  label: string;
  preview?: boolean;
  subnav?: {
    sections?: HeaderLinkSection[];
    featured?: HeaderLinkFeatured;
    cta?: boolean;
  };
}

const variants = {
  initial: {
    y: -20,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: { staggerChildren: 0.075, ease: 'easeInOut', duration: 0.01 },
  },
  exit: {
    y: 20,
    opacity: 0,
  },
};

export const NavigationHeaderLinkList = ({ links, secondaryLinks, isNavOpen = false, setIsNavOpen, isMobile = false }: NavigationHeaderLinksProps) => {
  useEffect(() => {
    if (isBrowser) {
      if (isMobile && isNavOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    }
  }, [isNavOpen, isMobile]);

  return (
    <>
      <motion.div
        className={`${
          isMobile && isNavOpen ? 'wrapper fixed h-[100vh] w-full overflow-y-scroll bg-white pb-[150px]' : 'max-lg:pointer-events-none max-lg:hidden'
        } left-0 top-[56px] grow justify-start text-inherit max-lg:w-full lg:top-[100%] lg:flex lg:items-center lg:text-center`}
        role="navigation"
      >
        <div className="max-lg:content-wrapper-x flex w-full max-lg:flex-col">
          <motion.div className={`flex grow max-lg:flex-col lg:space-x-4 xl:space-x-6`}>
            {links.map((link, index) => (
              <NavigationHeaderLinkItem
                link={link}
                index={index}
                isMobile={isMobile}
                isNavOpen={isNavOpen}
                setIsNavOpen={setIsNavOpen}
                key={link.label + index}
              />
            ))}
          </motion.div>
          <motion.div className={`flex max-lg:flex-col lg:space-x-4 xl:space-x-6`}>
            {secondaryLinks.map((link, index) => (
              <NavigationHeaderLinkItem
                link={link}
                index={index}
                isMobile={isMobile}
                isNavOpen={isNavOpen}
                setIsNavOpen={setIsNavOpen}
                key={link.label + index}
                secondary
              />
            ))}
          </motion.div>
        </div>
      </motion.div>
    </>
  );
};
