'use client';

/* eslint-disable max-len */
import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { AnimatePresence, motion, useSpring, useTransform } from 'framer-motion';
import { SplitH2, useInViewScroll, useWindowSize, useScrolledTheme, useSanitizedTranslation } from 'ui';
import { makeVariants } from '../utils/makeVariants';

export interface VideoCarouselBlockProps {}

const videos = [
  {
    video: 'https://cdn.muse.ai/u/SsDSBxC/6260884795e33fdec34166e2d3e004d44792902c34516e4b51b0bfe087432ca1/videos/hls.m3u8',
  },
  {
    video: 'https://cdn.muse.ai/u/SsDSBxC/17c53e7f8d4868fab187c69720691932a9c1684a9879587a2fc3ebacc369e5a2/videos/hls.m3u8',
  },
  {
    video: 'https://cdn.muse.ai/u/SsDSBxC/27cd59e1f67901e8dfba14c9f6bfc9d0078f6a97bf8130043c5ce5acb27b27be/videos/hls.m3u8',
  },
  {
    video: 'https://cdn.muse.ai/u/SsDSBxC/6260884795e33fdec34166e2d3e004d44792902c34516e4b51b0bfe087432ca1/videos/hls.m3u8',
  },
  {
    video: 'https://cdn.muse.ai/u/SsDSBxC/17c53e7f8d4868fab187c69720691932a9c1684a9879587a2fc3ebacc369e5a2/videos/hls.m3u8',
  },
  {
    video: 'https://cdn.muse.ai/u/SsDSBxC/27cd59e1f67901e8dfba14c9f6bfc9d0078f6a97bf8130043c5ce5acb27b27be/videos/hls.m3u8',
  },
];

const Slide = ({ relativeIndex, direction, id, componentInView }) => {
  const currentId = (videos.length + relativeIndex + id) % videos.length;
  const vidRef = useRef();

  useEffect(() => {
    const loadVideoPlayer = async () => {
      const video = vidRef.current;
      const videoSrc = videos[currentId].video;

      if (videoSrc.endsWith('.m3u8')) {
        const Hls = (await import('hls.js')).default;
        if (Hls.isSupported()) {
          const hls = new Hls();
          hls.loadSource(videoSrc);
          hls.attachMedia(video);
        } else {
          video.src = videoSrc;
        }
      } else if (videoSrc.endsWith('.mpd')) {
        const dashjs = (await import('dashjs')).default;
        const player = dashjs.MediaPlayer().create();
        player.initialize(video, videoSrc, false);
      } else {
        video.src = videoSrc;
      }

      if (relativeIndex === 0 && componentInView) {
        video.currentTime = 2.85;
        video.play();
      } else {
        video.currentTime = 10;
        video.pause();
      }
    };

    if (vidRef.current) {
      loadVideoPlayer();
    }
  }, [relativeIndex, componentInView, currentId]);

  return (
    <motion.div
      custom={direction}
      initial={direction => ({
        opacity: 0,
        scale: relativeIndex === 0 ? 1 : 0.5,
        x: -50 * relativeIndex + -5 * -direction + '%',
        filter: relativeIndex === 0 ? 'brightness(1)' : 'brightness(0.2)',
        zIndex: relativeIndex === 0 ? 10 : 1,
      })}
      animate={{
        opacity: 1,
        scale: relativeIndex === 0 ? 1 : 0.5,
        filter: relativeIndex === 0 ? 'brightness(1)' : 'brightness(0.2)',
        x: -50 * relativeIndex + '%',
        zIndex: relativeIndex === 0 ? 10 : 1,
      }}
      exit={direction => ({
        opacity: 0,
        filter: relativeIndex === 0 ? 'brightness(1)' : 'brightness(0.2)',
        x: -50 * relativeIndex + 5 * -direction + '%',
        scale: relativeIndex === 0 ? 1 : 0.5,
        zIndex: relativeIndex === 0 ? 10 : 1,
        transition: { duration: 0.15 },
      })}
      key={'test-id-' + currentId}
      className={'type-body-bold absolute aspect-video h-[160px] overflow-hidden rounded-md bg-white shadow-xl lg:h-[300px] xl:h-[500px]'}
    >
      <video ref={vidRef} loop muted playsInline className="h-full w-full" />
    </motion.div>
  );
};

export const VideoCarouselBlock: React.FC<VideoCarouselBlockProps> = ({}) => {
  const [[id, direction, random], setPage] = useState([0, 0, Math.random()]);

  const { t } = useSanitizedTranslation();

  // TODO: cms

  const backgroundColor = 'white';
  const primary = 'black';
  const heading = 'See Xumo TV in Action';
  const body = '';

  const Heading = SplitH2;

  const { ref, progress } = useInViewScroll([0, 1], ['start 50vh', 'end 50vh']);
  const movement = useSpring(progress, { stiffness: 400, damping: 90 });
  const y = useTransform(progress, [0, 1], ['0', '-15vh']);
  const opacity = useTransform(progress, [0, 0.4, 0.6, 1], [0.05, 1, 1, 0.05]);

  const router = useRouter();

  const variants = makeVariants.slideIn();

  const { width } = useWindowSize();
  const isMobile = width <= 719;

  const sectionRef = useRef();
  const { componentInView, colors } = useScrolledTheme({
    ref,
    background: '#E0E7ED',
    accent: '#000000',
    text: '#000000',
  });

  const setTestimonial = useCallback(
    nid => {
      const newDirection = nid > id ? 1 : -1;

      const newId = (nid + videos.length) % videos.length;
      setPage([newId, newDirection, Math.random()]);
    },
    [id],
  );

  return (
    <>
      <motion.section className="w-full overflow-hidden sm:pb-0 sm:pt-0" data-testid={'swirl-section'}>
        <motion.div
          ref={ref}
          initial="initial"
          whileInView="animate"
          viewport={{ once: true, margin: '-50% 0% -50% 0%' }}
          variants={variants}
          style={{ color: colors.text }}
          className={`wrapper relative relative z-10 mx-auto flex min-h-[400px] w-full flex-col items-center justify-center py-16 lg:py-32`}
        >
          <Heading variants={variants} content={heading} className={`type-heading z-10 flex flex-col text-inherit`} />
          {body && <motion.div variants={variants} className="type-body py-4 text-inherit lg:w-1/2" data-i18n dangerouslySetInnerHTML={{ __html: t(body) }} />}
          <div className="mt-16 flex w-full items-center justify-center md:w-2/3 xl:w-1/2">
            <button aria-label="Previous video" className="relative left-2 z-50 p-4 invert lg:-left-1/4" onClick={() => setTestimonial(id - 1)}>
              <img src="/static/icons/arrow.svg" alt="" className="min-h-[30px] min-w-[30px]" />
            </button>
            <div className={`flex aspect-video h-[160px] w-full justify-center lg:h-[300px] xl:h-[500px]`}>
              <motion.div layout className="relative flex items-center justify-center">
                <AnimatePresence custom={direction}>
                  <Slide key={'slide' + ((videos.length + -1 + id) % videos.length)} relativeIndex={-1} id={id} direction={direction} />
                  <Slide
                    componentInView={componentInView}
                    key={'slide' + ((videos.length + 0 + id) % videos.length)}
                    relativeIndex={0}
                    id={id}
                    direction={direction}
                  />
                  <Slide key={'slide' + ((videos.length + 1 + id) % videos.length)} relativeIndex={1} id={id} direction={direction} />
                </AnimatePresence>
              </motion.div>
            </div>
            <button aria-label="Next video" className="relative right-2 z-50 rotate-180 p-4 invert lg:-right-1/4" onClick={() => setTestimonial(id + 1)}>
              <img src="/static/icons/arrow.svg" alt="" className="min-h-[30px] min-w-[30px]" />
            </button>
          </div>
        </motion.div>
      </motion.section>
    </>
  );
};
