/* eslint-disable sonarjs/no-duplicate-string */
'use client';

import React, { useRef, useEffect, Suspense, MutableRefObject } from 'react';
import { Canvas, useFrame, useLoader } from '@react-three/fiber';
import { Html, OrbitControls } from '@react-three/drei';
import { TextureLoader, SRGBColorSpace, Vector3 } from 'three';
import { motion } from 'framer-motion-3d';
import { IImage } from '../types';
import Image from 'next/image';

const data: MarkerPin[] = [
  {
    name: 'USA',
    channels: 577,
    lat: 37.09024,
    long: -95.712891,
    more: true,
    logos: [{ src: '/static/images/dist-logos/samsung.png' }, { src: '/static/images/dist-logos/google.png' }, { src: '/static/images/dist-logos/roku.png' }],
  },
  {
    name: 'UK',
    channels: 40,
    lat: 55.378051,
    long: -3.435973,
    more: true,
    logos: [
      { src: '/static/images/dist-logos/samsung.png' },
      { src: '/static/images/dist-logos/amazon.png' },
      { src: '/static/images/dist-logos/rakuten.png' },
    ],
  },
  {
    name: 'Switzerland',
    channels: 3,
    lat: 46.818,
    long: -8.227,
    logos: [{ src: '/static/images/dist-logos/samsung.png' }],
  },
  {
    name: 'Sweden',
    channels: 4,
    lat: 60.128161,
    long: 18.643501,
    logos: [{ src: '/static/images/dist-logos/samsung.png' }, { src: '/static/images/dist-logos/rakuten.png' }],
  },
  {
    name: 'Spain',
    channels: 17,
    lat: 40.463667,
    long: -3.74922,
    logos: [{ src: '/static/images/dist-logos/samsung.png' }, { src: '/static/images/dist-logos/lg.png' }, { src: '/static/images/dist-logos/rakuten.png' }],
  },
  {
    name: 'Norway',
    channels: 1,
    lat: 60.472024,
    long: 8.468946,
    logos: [{ src: '/static/images/dist-logos/rakuten.png' }],
  },
  {
    name: 'Netherlands',
    channels: 4,
    lat: 52.132633,
    long: 5.291266,
    logos: [{ src: '/static/images/dist-logos/samsung.png' }],
  },
  {
    name: 'Mexico',
    channels: 40,
    lat: 23.634501,
    long: -102.552784,
    more: true,
    logos: [{ src: '/static/images/dist-logos/samsung.png' }, { src: '/static/images/dist-logos/hisense.png' }, { src: '/static/images/dist-logos/lg.png' }],
  },
  {
    name: 'Germany',
    channels: 24,
    lat: 51.165691,
    long: 10.451526,
    more: true,
    logos: [{ src: '/static/images/dist-logos/samsung.png' }, { src: '/static/images/dist-logos/amazon.png' }, { src: '/static/images/dist-logos/lg.png' }],
  },
  {
    name: 'Italy',
    channels: 14,
    lat: 41.87194,
    long: 12.56738,
    logos: [{ src: '/static/images/dist-logos/samsung.png' }, { src: '/static/images/dist-logos/lg.png' }, { src: '/static/images/dist-logos/rakuten.png' }],
  },
  {
    name: 'France',
    channels: 13,
    lat: 46.227638,
    long: 2.213749,
    logos: [{ src: '/static/images/dist-logos/lg.png' }, { src: '/static/images/dist-logos/rakuten.png' }],
  },
  {
    name: 'Finland',
    channels: 1,
    lat: 61.92411,
    long: 25.748151,
    logos: [{ src: '/static/images/dist-logos/rakuten.png' }],
  },
  {
    name: 'Denmark',
    channels: 1,
    lat: 56.26392,
    long: 9.501785,
    logos: [{ src: '/static/images/dist-logos/rakuten.png' }],
  },
  {
    name: 'Canada',
    channels: 73,
    lat: 56.130366,
    long: -106.346771,
    more: true,
    logos: [{ src: '/static/images/dist-logos/samsung.png' }, { src: '/static/images/dist-logos/lg.png' }, { src: '/static/images/dist-logos/roku.png' }],
  },
  {
    name: 'Brazil',
    channels: 17,
    lat: -14.235004,
    long: -51.92528,
    logos: [{ src: '/static/images/dist-logos/samsung.png' }],
  },
  {
    name: 'Austria',
    channels: 3,
    lat: 47.516231,
    long: 14.550072,
    logos: [{ src: '/static/images/dist-logos/samsung.png' }],
  },
  {
    name: 'Australia',
    channels: 6,
    lat: -25.274398,
    long: 133.775136,
    logos: [{ src: '/static/images/dist-logos/samsung.png' }],
  },
];

const lerp = (start: number, end: number, amt: number): number => (1 - amt) * start + amt * end;

const calcPosFromLatLonRad = (lat: number, lon: number, radius: number): Vector3 => {
  let x, y, z;

  let phi = (90 - lat) * (Math.PI / 180);
  let theta = (lon + 180) * (Math.PI / 180);

  x = -(radius * Math.sin(phi) * Math.cos(theta));
  z = radius * Math.sin(phi) * Math.sin(theta);
  y = radius * Math.cos(phi);

  return [x, y, z] as unknown as Vector3;
};

type MarkerPin = { channels: number; lat: number; long: number; name: string; logos: IImage[]; more?: boolean };

const Marker: React.FC<
  React.PropsWithChildren<{
    pin: MarkerPin;
    isHovered: MutableRefObject<boolean>;
    index: number;
    radius: number;
  }>
> = ({ children, ...props }) => {
  const { pin, index, radius } = props;
  let size = lerp(20, 60, pin.channels / 577);

  return (
    <>
      <Html wrapperClass="markerContainer" center position={calcPosFromLatLonRad(pin.lat, pin.long, radius)}>
        <div
          onMouseEnter={() => {
            setTimeout(() => {
              props.isHovered.current = true;
            }, 10);
          }}
          className="marker"
          style={{
            backgroundImage: 'url(/static/textures/pin-bg.jpg)',
            width: size + 'px',
            height: size + 'px',
            transition: 'all 0.2s',
            backgroundPositionX: ((index % 4) / 4) * 100 + '%',
            backgroundPositionY: (Math.floor(index / 4) % 2) * 50 + '%',
          }}
        />
        <div
          className="marker markerRipple"
          style={{
            width: size + 'px',
            height: size + 'px',
            backgroundPositionX: ((index % 4) / 4) * 100 + '%',
            backgroundPositionY: (Math.floor(index / 4) % 2) * 50 + '%',
          }}
        ></div>
        <div style={{ left: size + 8 + 'px' }} className="tooltip">
          <h3 data-i18n className="type-body weight-500 fadein">{pin.name}</h3>
          <div data-i18n className="type-body-base fadein mb-4" dangerouslySetInnerHTML={{ __html: `${pin.channels}&nbsp;Channels` }} />
          <div className="space-y-2">
            {pin.logos.map(logo => (
              <div className="relative h-[32px] w-auto">
                <Image className="h-full w-full object-contain" fill src={logo.src} alt={logo.alt ?? ''} />
              </div>
            ))}
          </div>
          {pin.more && <div data-i18n className="text-body-base mt-4 text-sm">plus more!</div>}
        </div>
      </Html>
    </>
  );
};

const Markers: React.FC<React.PropsWithChildren<{ isHovered: MutableRefObject<boolean>; radius: number; markers: MarkerPin[] }>> = ({ children, ...props }) => {
  //Utility functions

  let myPins = useRef<any>();
  let center = useRef<any>();

  useEffect(() => {
    // code to run after render goes here
    myPins.current = document.querySelectorAll('.markerContainer');
    center.current = document.querySelector('.center');
  }, []);

  useFrame((state, dt) => {
    myPins.current &&
      myPins.current.forEach((element: any) => {
        const { current: centerElement } = center;
        element.style.zIndex < centerElement.style.zIndex ? element.classList.add('occluded') : element.classList.remove('occluded');
      });
  });

  return (
    <>
      <Html pointerEvents="none" wrapperClass="center" position={[0, 0, 0]}></Html>
      {props.markers.map((object, i) => (
        <Marker key={i} index={i} isHovered={props.isHovered} radius={props.radius * 1.02} pin={object} />
      ))}
    </>
  );
};

const Globe: React.FC<{ markers: MarkerPin[] }> = ({ markers }) => {
  const isHovered = useRef(false);
  const meshRef = useRef<any>();

  const map = useLoader(TextureLoader, '/static/textures/earth-texture4k.png');
  map.colorSpace = SRGBColorSpace;

  useFrame((state, dt) => {
    if (!isHovered.current) {
      meshRef.current.rotation.y += 0.1 * dt;
    }
  });

  const globeRadius = 2.75;
  const rotation = 0.8;

  return (
    <>
      <OrbitControls enableZoom={false} enablePan={false} target={[0, 0, 0]} maxPolarAngle={Math.PI / 2} minPolarAngle={Math.PI / 2 - 0.5} />
      <directionalLight castShadow position={[3, 4, 5]} intensity={0.5} />
      <ambientLight intensity={1} />
      <motion.mesh
        onPointerEnter={e => {
          isHovered.current = true;
        }}
        onPointerLeave={e => {
          isHovered.current = false;
        }}
        ref={meshRef}
        rotation-x={0}
        rotation-z={0}
      >
        <sphereGeometry args={[globeRadius, 128, 64]} />
        <meshStandardMaterial map={map} />
        <Markers radius={globeRadius} isHovered={isHovered} markers={markers} />
      </motion.mesh>
    </>
  );
};

export interface XumoGlobeProps {
  markers?: MarkerPin[];
}

export const XumoGlobe: React.FC<XumoGlobeProps> = ({ markers = data }) => {
  return (
    <Canvas camera={{ position: [0, 5, 0] }}>
      <Suspense fallback={null}>
        <Globe markers={data} />
      </Suspense>
    </Canvas>
  );
};
