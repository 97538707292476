/* eslint-disable max-len */
import { motion, MotionValue, useSpring, useTransform } from 'framer-motion';
import React from 'react';
import { Button, SplitH1, SplitH2 } from '.';
import { useInViewScroll, useScrolledTheme } from '../hooks';
import { CTA, IImage } from '../types/common';
import { makeVariants } from '../utils/makeVariants';
import { MotionImage } from './AnimatedImage';

export interface ImageHoldingShapeBlockProps {
  heading: string;
  image: IImage;
  body: string;
  cta: CTA;
}

export const ImageHoldingShapeBlock: React.FC<ImageHoldingShapeBlockProps> = (
  {
    // heading,
    // cta,
    // image,
    // body
  },
) => {
  // TODO: CMS
  const heading = 'Xumo Stream Box';
  const body = 'Get all the top streaming apps in one place.<br/> Just plug it in to watch the TV you love – fast.';
  const cta = {
    label: 'Shop now',
    href: '#about',
    backgroundColor: '#BB2E76',
    textColor: '#fff',
  };

  const appCta = {
    label: 'See all apps',
    href: '#apps',
    backgroundColor: '#BB2E76',
    textColor: '#fff',
  };

  const image = { src: '/static/images/streambox-holding-alt.png' };

  const { ref, progress } = useInViewScroll([0, 1], ['start 100vh', 'end 0vh']);
  const progressAmount = useTransform(progress, [0, 1], ['-10%', '0%'], { clamp: false });
  const y: MotionValue<string> = useSpring(progressAmount, { stiffness: 400, damping: 90 });

  const variants = makeVariants.slideIn();

  const { componentInView, colors } = useScrolledTheme({
    ref,
    background: '#E0E7ED',
    accent: '#000000',
    text: '#000000',
  });

  return (
    <section ref={ref} className="mt-[-130px] w-full pb-16 lg:mt-[-100px]" data-testid="shape-section">
      <motion.div
        variants={variants}
        initial="initial"
        animate="animate"
        className={`relative flex w-full flex-col items-center overflow-hidden pb-6 md:min-h-[850px]`}
      >
        <motion.div className="container relative z-20 mx-auto flex max-w-screen-lg flex-col px-0 pt-[6rem] max-[768px]:mt-[9rem] max-sm:flex-col-reverse max-[420px]:mt-0 min-[500px]:px-6 min-[600px]:pt-[3rem] xl:pt-[9rem]">
          <motion.div className="relative z-10 h-full min-h-[400px] w-full items-center overflow-hidden rounded-full">
            <MotionImage
              className=" h-full w-full object-cover"
              style={{ y, scale: 1.1, transformOrigin: 'top' }}
              src={image.src}
              alt={heading}
              priority
              fill
              quality={90}
              sizes={'100vw'}
            />
          </motion.div>
          <div className="z-10 mt-8 flex w-full flex-col items-center justify-center sm:w-3/4 md:w-full" style={{ color: colors.text }}>
            <SplitH1 content={heading} className="type-title my-8 text-center text-5xl max-sm:text-[28px]" />
            <SplitH2
              content={body}
              variants={{ ...variants, animate: { ...variants.animate, transition: { staggerChildren: 0.075, ease: 'easeOut', duration: 0.5, delay: 0.4 } } }}
              className="type-body mb-[51px] text-center max-sm:leading-5"
            />
          </div>
        </motion.div>
        <motion.div className="flex flex-col pt-6 max-md:space-y-3 md:flex-row md:space-x-6">
          <Button
            label={cta.label}
            // icon={{ name: 'chevron-down', placement: 'right', color: cta.textColor || '#fff' }}
            href={cta.href}
            buttonType="link-primary"
            key={cta.label}
            styleOverrides={{
              css: { backgroundColor: cta.backgroundColor || '#155772', color: cta.textColor || '#fff' },
              tailwind: `w-[170px] h-[56px] font-[700] text-[18px] mt-5`,
            }}
            data-i18n={cta.label}
          />
          <Button
            label={appCta.label}
            // icon={{ name: 'chevron-down', placement: 'right', color: appCta.textColor || '#fff' }}
            href={appCta.href}
            buttonType="link-primary"
            key={appCta.label}
            styleOverrides={{
              css: { backgroundColor: appCta.backgroundColor || '#155772', color: appCta.textColor || '#fff' },
              tailwind: `w-[170px] h-[56px] font-[700] text-[18px] mt-5`,
            }}
            data-i18n={appCta.label}
          />
        </motion.div>
      </motion.div>
    </section>
  );
};
