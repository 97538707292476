/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-len */
import { motion, useSpring, useTransform, AnimatePresence } from 'framer-motion';
import React, { useCallback, useRef, useState } from 'react';
import { useInViewScroll, useScrolledTheme, useWindowSize, useSanitizedTranslation } from '../hooks';
import { makeVariants } from '../utils/makeVariants';
import { SplitH1, XumoButton as Button, SplitH4, MotionImage } from 'ui';
import Link from 'next/link';
import Image from 'next/image';

import { CTA, Colors, IImage } from '../types';
import { useRouter } from 'next/router';

export type AdvertisingHeroProps = {
  heading: string;
  body?: string;
  ctas: CTA[];
  image: IImage;
};

const squiggleVariant = {
  hidden: {
    opacity: 0,
    y: 30,
    transition: {
      when: 'afterChildren',
      delayChildren: 0,
      staggerChildren: 0.025,
      type: 'spring',
      stiffness: 80,
      damping: 35,
    },
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      staggerChildren: 0.055,
      type: 'spring',
      stiffness: 90,
      damping: 23,
    },
  },
};

const colorFallback = {
  '/shop': '#396059',
  '/shop/xumo-tv': '#0029FF',
  '/shop/xumo-stream-box': '#0029FF',
  '/shop/streaming': '#0029FF',
};

const copyVariants = {
  hidden: {
    opacity: 0,
    y: 30,
    transition: {
      when: 'afterChildren',
      delayChildren: 0,
      staggerChildren: 0.01,
      type: 'spring',
      stiffness: 400,
      damping: 100,
    },
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      delayChildren: 0,
      staggerChildren: 0.025,
      type: 'spring',
      stiffness: 80,
      damping: 23,
    },
  },
};

export const AdvertisingHero: React.FC<AdvertisingHeroProps> = props => {
  const { image, ctas } = props;
  const { width } = useWindowSize();

  const heading = 'Welcome to your backstage pass to reach the next generation of streaming audiences';
  const body = 'Built to be an experience viewers love, and advertisers want';

  const isMobile = width <= 767;

  const { ref, progress: y } = useInViewScroll([0, 1], ['start 100vh', 'end 0vh']);
  const amt = useSpring(y, { stiffness: 400, damping: 90 });
  const containerY = useTransform(amt, [0, 1], ['0', '-15vh']);
  const opacity = useTransform(amt, [0, 0.4, 0.6, 1], [1, 1, 0.85, 0.05]);

  const router = useRouter();
  const fallbackColor = colorFallback[router.asPath as keyof typeof colorFallback];
  const variants = makeVariants.slideIn();

  const { componentInView, colors } = useScrolledTheme({
    ref,
    background: '#efefef',
    accent: fallbackColor || '#2AAC00',
    text: '#ffffff',
  });

  return (
    <>
      <div className="relative mb-4 w-full md:mb-8">
        {/* <section
        ref={ref}
        className={`relative top-0 flex w-full flex-col`}
        data-id={'video-hero'}
        style={{ minHeight: !isMobile ? 520 : '100%' }}
      >
        <motion.div
          variants={variants}
          initial="initial"
          whileInView={'animate'}
          viewport={{ once: true }}
          className={`wrapper relative z-[2] flex h-full flex-col`}
          style={{ color: Colors.Black, opacity: 0 }}
        >
          <div className="absolute left-0 right-0 bottom-0 top-0 h-full w-full z-0">
            <MotionImage
                className={`w-full object-cover max-sm:h-full brightness-50`}
                sizes="(max-width: 768px) 100vw, (max-width: 1600px) 80vw, 75vw"
                quality={80}
                src={'/static/images/ad-hero.png'}
                alt={''}
                priority
                fill
            />
          </div>
          <div className="w-full text-left relative z-10">
            
            <motion.div
              initial="hidden"
              animate="show"
              variants={variants}
              className="flex w-full flex-col justify-end text-left text-white max-lg:pb-[50px] max-lg:pt-[100px] lg:h-[calc(100vh-100px)] lg:min-h-[500px]"
            >
              <div className="w-full max-md:text-center pb-8">

                <h1 className={`text-center md:text-left type-heading w-full lg:w-3/4 xl:w-2/3 text-[${isMobile ? '40px' : '56px'}]`} dangerouslySetInnerHTML={{__html: heading }}/>
                {body ? (
                  <div className="flex flex-row pt-[24px]">
                    <SplitH4 content={body} variants={copyVariants} className={`type-body-small text-center md:text-left w-full lg:w-3/4 xl:w-2/3 text-[${isMobile ? '16px' : '24px'}]`} />
                  </div>
                ) : null}
                <motion.div
                  style={{ paddingTop: isMobile ? '33px' : '48px', flexDirection: isMobile ? 'column-reverse' : 'row' }}
                  variants={copyVariants}
                  className={`flex flex-col md:flex-row justify-between items-center`}
                >
                  <Button
                    styleOverrides={{
                      css: { backgroundColor: "white", color: "black" },
                      tailwind: `w-[166px] h-[56px] md:mr-4`,
                    }}
                    href="#contact"
                    label={"Get in touch"}
                    buttonType="link-primary"
                  />

                  <div className="flex max-md:mt-8">
                    <span className="type-body text-left text-sm leading-tight inline-block mr-4 ">A joint venture<br/> between</span>
                    <Image src="/static/images/joint-logos.png" alt="Comcast and Charter" width={200} height={75} className="object-contain" />
                  </div>
                </motion.div>
              </div>
            </motion.div>
          </div>
        </motion.div>
        
      </section> */}

        <ImageParallaxHero />
      </div>
    </>
  );
};

const ImageParallaxHero = ({ content, bg }) => {
  const { t } = useSanitizedTranslation();
  const { ref, progress: amt } = useInViewScroll([0, 1], ['start 100px', 'end 0vh']);
  const scrollY = useTransform(amt, [0, 1], [0, 350]);

  const [heroLoaded, setLoaded] = useState(false);
  const imageLoaded = () => setLoaded(true);
  const fadeInProps = {
    onLoadingComplete: imageLoaded,
    initial: { opacity: 0 },
    animate: { opacity: heroLoaded ? 1 : 0 },
  };

  const heading = 'Welcome to your backstage pass to reach the next generation of streaming audiences';
  const body = 'Built to be an experience viewers love, and advertisers want';

  return (
    <motion.section className="relative z-40 my-8 flex w-full justify-center bg-white" data-testid={'text-section'}>
      <div className="wrapper">
        <div className={`relative flex overflow-hidden rounded-3xl bg-xumoBlack`}>
          <div className="absolute left-0 top-0 h-[calc(100%+100px)] w-full">
            <MotionImage
              style={{ y: scrollY }}
              src={'https://xumo-cms.directus.app/assets/211e5a4f-9ab9-4784-bd99-ab80958df27f'}
              alt=""
              fill
              priority
              {...fadeInProps}
              className="-top-8 object-cover brightness-90"
              sizes="100vw"
            />
          </div>

          <div style={{ background: 'linear-gradient(to right, #000a 25%, #0007 60%, #0000 100%' }} className="absolute left-0 top-0 h-full w-full" />
          {/* <div clasName="w-3/4 text-center" dangerouslySetInnerHTML={{ __html: t(content) }} /> */}
          <div className="flex max-lg:flex-col">
            <div className="content-wrapper relative z-10 lg:mt-16 lg:w-2/3">
              <img alt="Xumo Advertising" src="/static/images/xumo-advertising-logo.png" className="w-[250px] pb-8" />
              <h1 data-i18n className="type-heading weight-300 pb-8 leading-[1.05] text-white">{heading}</h1>
              <p data-i18n className="type-body mb-8 leading-tight text-white">{body}</p>
              <Button href="#contact" label={'Get in touch'} light />
            </div>
            <div className="content-wrapper z-10 flex max-md:mt-8 lg:items-end lg:justify-end ">
              <span data-i18n className="mr-4 inline-block text-left text-sm leading-tight text-white ">
                A joint venture
                <br /> between
              </span>
              <Image src="/static/images/joint-logos.png" alt="Comcast and Charter" width={200} height={75} className="object-contain md:mb-3 2xl:mb-0" />
            </div>
          </div>
        </div>
      </div>
    </motion.section>
  );
};
