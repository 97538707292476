/* 
Cookie Consent implementation is provided by Comcast
via a third-party, Securiti.ai
*/

import { useTranslation } from 'next-i18next';
import Script from 'next/script';
import Head from 'next/head';

// Add to Head in _document.tsx
export const CookieConsentStyles = () => {
  const isProd = true; // process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === 'production'
  const href = isProd ? 'https://cdn.comcast.com/cmp/css/cookie-consent.css' : 'https://cdn.stg.comcast.com/cmp/css/cookie-consent.css';

  return <link rel="stylesheet" type="text/css" href={href} />;
};

// Add to the bottom of the page
export const CookieConsentFooter = () => {
  const { t } = useTranslation('common');

  return (
    <div className="cmp-revoke ot-sdk-btn relative z-30 w-full">
      <button id="ot-sdk-btn" className="ot-sdk-show-settings">
        Cookie Preferences
      </button>
    </div>
  );
};

export const CookieConsentScripts = () => {
  const isProd = true; // process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === 'production'
  const uuid = isProd ? '1d6bf67a-57ee-4c7c-90e9-3c3a1dba073a' : '5eee67c3-cddb-4218-bf0d-dd3e73db9d1b';

  return (
    <>
      {/* <Script type="text/javascript" id="cookie-consent">{`
			(function () {
				var s = document.createElement('script');
				s.src = 'https://cdn-prod.securiti.ai/consent/cookie-consent-sdk.js'; 
				s.setAttribute('data-tenant-uuid', '4b9bbe2a-9c5d-4512-bb76-768a8ea32bc0'); 
				s.setAttribute('data-domain-uuid', '${uuid}'); 
				s.setAttribute('data-backend-url', 'https://app.securiti.ai');
				s.defer = true;
				var parent_node = document.head || document.body; 
				parent_node.appendChild(s);
				s.addEventListener('load', function() { window.initCmp(); });
			})()
		`}</Script> */}
    </>
  );
};
