import { DirectusBubbleBlock } from 'config';
import { BubbleBlockProps } from '../../../components';
import { mapDirectusCTAs, mapDirectusImage } from '../general';

export const mapDirectusBubbleBlock = (block: DirectusBubbleBlock): BubbleBlockProps => {
  return {
    bubbles: (block as any)?.bubbles?.map((bubble: any) => mapDirectusImage(bubble.directus_files_id)),
    header: block.heading ?? '',
    cta: block.cta_items?.length ? mapDirectusCTAs(block.cta_items, undefined, true) : [],
    body: (block as any)?.body ?? '',
    backgroundColor: block.background_color ?? 'white',
    accent: block.accent ?? 'blue',
    bubble: block.bubble,
  };
};
